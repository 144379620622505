<template>
  <div class="gallery-container">
    <div
      v-if="media.status === 'success'"
      :style="`--gallery-columns:${columns}`"
      :class="[imageCrop ? 'square' : 'original-ratio']"
      class="gallery"
    >
      <figure
        v-for="(image, index) in filteredMedia"
        class="image-container"
        :key="index"
      >
        <img
          v-lazy="{
            src: image.imageSize.M,
            loading: image.preview
          }"
          :height="image.height"
          :width="image.width"
          :data-srcset="image.srcset"
          :tabindex="index"
          sizes="20vw"
          class="image"
          loading="lazy"
          @click="setActiveImageID(image.id)"
        />
        <blur-hash-canvas
          width="200"
          height="200"
          :hash="image.blurhash"
          :punch="1.5"
        />
      </figure>
    </div>
    <hue7-lightbox
      v-if="lightbox.activeImageID"
      :images="filteredMedia"
      :ids="ids"
    />
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import Hue7Lightbox from '@/components/blocks/Hue7Lightbox.vue'
import { BlurHashCanvas } from 'vue-blurhash'

export default {
  name: 'Gallery',

  components: {
    Hue7Lightbox
  },

  props: {
    ids: {
      type: Array,
      required: true
    },
    columns: {
      type: [Number, Boolean],
      required: false,
      default: false
    },
    imageCrop: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  watch: {
    activeImageID(active) {
      active
        ? document.documentElement.classList.add('lightbox-visible')
        : document.documentElement.classList.remove('lightbox-visible')
      active
        ? disableBodyScroll(this.$refs.lightbox)
        : enableBodyScroll(this.$refs.lightbox)
    }
  },

  computed: {
    ...mapState(['media', 'lightbox']),

    filteredMedia() {
      return this.media.data
        .filter(({ id }) => this.ids.includes(id))
        .sort((a, b) => {
          return this.ids.indexOf(a.id) > this.ids.indexOf(b.id) ? 1 : -1
        })
    },

    activeImageID() {
      return this.lightbox.activeImageID
    }
  },

  methods: {
    ...mapMutations({
      setActiveImageID: 'SET_LIGHTBOX_ACTIVE_IMAGE'
    })
  }
}
</script>

<style lang="scss">
.lightbox-visible .content {
  z-index: 6;
}

.content .blocks {
  .gallery-container {
    --spacing-width: 2rem;

    overflow: hidden;
    margin: calc(-1 * var(--spacing-width));
    padding: var(--spacing-width);

    *,
    & {
      user-select: none;
    }

    @media (max-width: 500px) {
      .gallery {
        grid-template-columns: 100% !important;
        margin: 0;
        padding: 0;
      }
    }
  }

  .gallery {
    --gallery-columns: auto-fit;

    display: grid;
    grid-gap: var(--gallery-gap, 1rem);
    grid-template-columns: repeat(var(--gallery-columns), 1fr);
    grid-auto-rows: max-content;
    align-items: stretch;
    justify-items: stretch;

    &.original-ratio {
      @media (min-width: 600px) {
        align-items: center;
        justify-items: center;
      }
    }

    &.square {
      .image-container {
        &::before {
          content: '';
          padding-top: 100%;
          display: block;
        }

        canvas,
        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }

        canvas {
          z-index: -1;
        }
      }
    }

    .image-container {
        overflow: hidden;
        position: relative;
        width: 100%;

        canvas {
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
        }

      img {
          display: block;
        border-radius: var(--border-radius);
        transition: calc(var(--transition-duration) * 0.75);
        transition-timing-function: ease-out;
        cursor: pointer;
        width: 100%;
        filter: blur(var(--blur, 0px)) saturate(var(--saturate, 1))
          brightness(var(--brightness, 1));
        transform: scale(var(--scale, 1));
        backface-visibility: hidden;

        &[lazy='loading'] {
          --blur: 12px;
          --scale: 1.15;
          --saturate: 1.5;
          --brightness: 1.05;
        }

        &[lazy='loaded'] {
          &:hover {
            --scale: 1.015;
          }

          &:active {
            --scale: 0.95;
          }
        }
      }
    }
  }
}
</style>
