<template>
    <footer id="footer" :class="{ 'multi-column': footerColumns.length > 1 }" class="footer">
        <div
            v-for="({ column }, index) in footerColumns"
            :key="index"
            class="column"
            v-html="footerTextLinkified(column)"
        />
    </footer>
</template>

<script>
import linkifyHtml from 'linkify-html'

export default {
    name: 'TheFooter',

    props: {
        footerColumns: {
            type: Array,
            required: true
        }
    },

    methods: {
        footerTextLinkified(text) {
            return linkifyHtml(text.replace(/(?:\r\n|\r|\n)/g, '<br>'))
        }
    }
}
</script>

<style lang="scss">
.footer {
    grid-area: footer;
    padding: var(--content-padding);
    background-color: var(--footer-background-color);
    background-image: var(--footer-image);
    background-size: cover;
    color: var(--footer-text-color);
    cursor: default;
    align-content: stretch;
    display: grid;
    grid-auto-flow: row;
    justify-content: stretch;
    align-items: start;
    grid-gap: 1rem;
    flex-grow: 1;

    a {
        color: var(--footer-link-color);
        transition: var(--transition-duration);
        text-decoration: underline;
        text-decoration-color: var(--footer-link-color);

        &:hover,
        &:focus {
            text-decoration-color: hsla(0, 0%, 100%, 1);
            filter: brightness(1.1);
        }
    }

    img {
        max-width: 100%;
        height: auto;
    }

    &.multi-column {
        justify-content: stretch;

        @media (min-width: 1000px) {
            justify-content: space-between;
        }
    }

    @media (min-width: 1000px) {
        grid-auto-flow: column;
    }
}

.sidebar-no-burger {
    .footer {
        @media (max-width: 700px) {
            margin-bottom: var(--navigation-height);
        }
    }
}

.sidebar-left {
    .footer {
        margin-right: 0;
    }
}

.sidebar-right {
    .footer {
        margin-left: 0;
    }
}

.sidebar-hidden {
    .footer {
        margin-right: 0;
        margin-left: 0;
    }
}
</style>
