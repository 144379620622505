<template>
  <header class="header">
    <transition name="grow">
      <img
        v-if="headerImage"
        :key="`image-${headerImage}`"
        :data-blurhash="blurhash"
        :src="imageSource"
        :srcset="imageSourceSet"
        :style="`object-position:${imageFocusX} ${imageFocusY}`"
        sizes="100vw"
      />
    </transition>
    <router-link
      :class="[
        `position-y-${logos.position.y}`,
        `position-x-${logos.position.x}`
      ]"
      :style="
        `
				--logo-position-x:${logos.position.x};
				--logo-position-y:${logos.position.y};
				--logo-margin:${logos.margin};
			`
      "
      to="/"
      class="logo-link"
    >
      <img :src="logos.large.image" class="logo-large" />
      <img :src="logos.small.image" class="logo-small" />
    </router-link>
  </header>
</template>

<script>
import { mapState } from 'vuex'
import { decode } from 'blurhash'

export default {
  name: 'TheHeader',

  props: {
    headerImage: {
      type: [Number, Boolean],
      required: true
    },
    logos: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapState(['media']),
    image() {
      return this.media.data.find(({ id }) => id == this.headerImage)
    },
    blurhash() {
      return this.image && this.image.blurhash
    },
    imageSource() {
      return this.image && this.image.src
    },
    imageSourceSet() {
      return this.image && this.image.srcset
    },
    imageFocusX() {
      return this.image && this.image.focus.x
    },
    imageFocusY() {
      return this.image && this.image.focus.y
    }
  }
}
</script>

<style lang="scss">
.header {
  grid-area: header;
  z-index: 2;
  overflow: hidden;
  background-color: hsl(0, 0%, 0%);

  img {
    height: var(--header-height);
    max-height: var(--header-height);
    width: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    display: block;
  }

  .logo-link {
    display: none;
  }

  .sidebar-hidden & {
    position: relative;

    .logo-link {
      display: block;
      height: var(--header-height);
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      transition: var(--transition-duration);

      img {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        object-fit: contain;
        object-position: var(--logo-position-y, center)
          var(--logo-position-x, center);
        transform: scale(var(--logo-large-scale));
        transform-origin: var(--logo-position-x, center)
          var(--logo-position-y, center);
        transition: var(--transition-duration);
        padding: var(--logo-margin, auto);

        &.logo-large {
          transform: scale(var(--logo-large-scale));
          display: none;

          &:hover {
            transform: scale(calc(var(--logo-large-scale) * 1.025));
          }

          &:active {
            transform: scale(calc(var(--logo-large-scale) * 0.985));
            transition: 100ms;
          }
        }

        &.logo-small {
          transform: scale(var(--logo-small-scale));

          &:hover {
            transform: scale(calc(var(--logo-small-scale) * 1.025));
          }

          &:active {
            transform: scale(calc(var(--logo-small-scale) * 0.985));
            transition: 100ms;
          }
        }

        @media (min-width: 700px) {
          &.logo-small {
            display: none;
          }
          &.logo-large {
            display: block;
          }
        }
      }
    }
  }
}
</style>
