<template>
  <div
    class="column"
    :style="`--width:${width};--vertical-alignment:${verticalAlignment}`"
  >
    <div
      v-for="(block, index) in column.innerBlocks"
      :key="index"
      v-html="block.innerHTML"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Hue7Column',

  props: {
    column: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapState(['media']),

    verticalAlignment() {
      let verticalAlignment = this.column.attrs.verticalAlignment
      verticalAlignment =
        verticalAlignment === 'bottom' ? 'flex-end' : verticalAlignment
      verticalAlignment =
        verticalAlignment === 'top' ? 'flex-start' : verticalAlignment
      return verticalAlignment || 'stretch'
    },

    width() {
      return this.column.attrs.width || 0
    }
  }
}
</script>

<style lang="scss">
.columns {
  @media (min-width: 800px) {
    --margin: 1.5rem;

    display: flex;
    margin: 0 calc(-1 * var(--margin, 1.5rem));
    flex-wrap: nowrap;
  }

  .column {
    align-self: var(--vertical-alignment);
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 100%;
    margin: 1rem 0;

    @media (min-width: 800px) {
      flex-basis: var(--width, auto);

      > div {
        padding: 0 var(--margin, 1.5rem);
      }

      a {
        display: inline-block;
      }
    }
  }
}
</style>
