import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    meta: {
      status: undefined,
      data: [],
    },
    options: {
      status: undefined,
      data: [],
    },
    navigation: {
      status: undefined,
      data: [],
    },
    pages: {
      status: undefined,
      data: [],
    },
    posts: {
      status: undefined,
      data: [],
    },
    media: {
      status: undefined,
      data: [],
    },
    forms: {
      status: undefined,
      data: [],
    },
    lightbox: {
      activeImageID: false,
    },
  },
  mutations: {
    SET_META(state, meta) {
      state.meta.data = meta
    },
    SET_META_STATUS(state, status) {
      state.meta.status = status
    },
    SET_OPTIONS(state, options) {
      state.options.data = options
    },
    SET_OPTIONS_STATUS(state, status) {
      state.options.status = status
    },
    SET_NAVIGATION(state, navigation) {
      state.navigation.data = navigation
    },
    SET_NAVIGATION_STATUS(state, status) {
      state.navigation.status = status
    },
    SET_PAGES(state, pages) {
      state.pages.data = pages
    },
    SET_PAGES_STATUS(state, status) {
      state.pages.status = status
    },
    SET_POSTS(state, posts) {
      state.posts.data = posts
    },
    SET_POSTS_STATUS(state, status) {
      state.posts.status = status
    },
    SET_MEDIA(state, media) {
      state.media.data = media
    },
    SET_MEDIA_STATUS(state, status) {
      state.media.status = status
    },
    SET_FORMS(state, forms) {
      state.forms.data = forms
    },
    SET_FORMS_STATUS(state, status) {
      state.forms.status = status
    },
    SET_LIGHTBOX_ACTIVE_IMAGE(state, id) {
      state.lightbox.activeImageID = id
    },
  },
  actions: {
    GET_META({ commit }) {
      commit('SET_META_STATUS', 'loading')
      axios
        .get(`/api`)
        .then(({ data }) => {
          commit('SET_META', data)
          commit('SET_META_STATUS', 'success')
        })
        .catch((error) => {
          commit('SET_META_STATUS', 'error')
          console.error(error)
        })
    },
    GET_OPTIONS({ commit }) {
      commit('SET_OPTIONS_STATUS', 'loading')
      axios
        .get(`/api/hue7/options`)
        .then(({ data }) => {
          commit('SET_OPTIONS', data)
          commit('SET_OPTIONS_STATUS', 'success')
        })
        .catch((error) => {
          commit('SET_OPTIONS_STATUS', 'error')
          console.error(error)
        })
    },
    GET_NAVIGATION({ commit }) {
      commit('SET_NAVIGATION_STATUS', 'loading')
      axios
        .get(`/api/hue7/menu`)
        .then(({ data }) => {
          commit(
            'SET_NAVIGATION',
            data.map((values) => {
              if (values.type !== 'custom') {
                values.link = new URL(values.url).pathname
              }
              return values
            })
          )
          commit('SET_NAVIGATION_STATUS', 'success')
        })
        .catch(() => {
          commit('SET_NAVIGATION_STATUS', 'error')
        })
    },
    GET_PAGES({ commit }) {
      commit('SET_PAGES_STATUS', 'loading')
      axios
        .get('/api/hue7/pages')
        .then(({ data }) => {
          commit(
            'SET_PAGES',
            data.map((values) => {
              values.blocks = values.blocks.filter(
                (block) => block.blockName !== null
              )
              return values
            })
          )
          commit('SET_PAGES_STATUS', 'success')
        })
        .catch((error) => {
          commit('SET_PAGES_STATUS', 'error')
          console.error(error)
        })
    },
    GET_POSTS({ commit }) {
      commit('SET_POSTS_STATUS', 'loading')
      axios
        .get('/api/hue7/posts')
        .then(({ data }) => {
          commit(
            'SET_POSTS',
            data.map((values) => {
              values.blocks = values.blocks.filter(
                (block) => block.blockName !== null
              )
              return values
            })
          )
          commit('SET_POSTS_STATUS', 'success')
        })
        .catch((error) => {
          commit('SET_POSTS_STATUS', 'error')
          console.error(error)
        })
    },
    GET_MEDIA({ commit }) {
      commit('SET_MEDIA_STATUS', 'loading')
      axios
        .get('/api/hue7/media')
        .then(({ data }) => {
          commit('SET_MEDIA', data)
          commit('SET_MEDIA_STATUS', 'success')
        })
        .catch((error) => {
          commit('SET_MEDIA_STATUS', 'error')
          console.error(error)
        })
    },
    GET_FORMS({ commit }) {
      commit('SET_FORMS_STATUS', 'loading')
      axios
        .get('/api/hue7/forms')
        .then(({ data }) => {
          commit('SET_FORMS', data)
          commit('SET_FORMS_STATUS', 'success')
        })
        .catch((error) => {
          commit('SET_FORMS_STATUS', 'error')
          console.error(error)
        })
    },
  },
})
