<template>
    <aside
        id="sidebar"
        :class="[
            `menu-${menuPosition}-logo`,
            `logo-${logoPosition}`,
            { burger: burger }
        ]"
        :style="`--body-height:${bodyHeight}px`"
        class="sidebar"
    >
        <router-link class="logo" to="/">
            <img
                :src="logoLarge.image"
                :style="`--logo-large-scale:${logoLarge.scale}`"
                class="logo-large"
            />
            <img
                :src="logoSmall.image"
                :style="`--logo-small-scale:${logoSmall.scale}`"
                class="logo-small"
            />
        </router-link>
        <div v-if="!burger" class="info-text" v-html="infoText" />
        <the-navigation v-if="burger" :info-text="infoText" :burger="burger" />
    </aside>
</template>

<script>
import TheNavigation from '@/components/TheNavigation.vue'

export default {
    name: 'TheSidebar',

    components: {
        TheNavigation
    },

    props: {
        logoSmall: {
            type: Object,
            required: true
        },
        logoLarge: {
            type: Object,
            required: true
        },
        menuPosition: {
            type: String,
            default: 'after'
        },
        logoPosition: {
            type: String,
            default: 'center'
        },
        infoText: {
            type: String,
            required: true
        },
        burger: {
            type: Boolean,
            required: true
        }
    },

    data() {
        return {
            bodyHeight: window.innerHeight
        }
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize)
    },

    methods: {
        handleResize() {
            this.bodyHeight = window.innerHeight
        }
    }
}
</script>

<style lang="scss">
:root:not(.menu-visible) .sidebar {
    overflow: hidden;
}

.sidebar-burger {
    &.sidebar-visible {
        .sidebar.burger {
            z-index: 5;

            &.menu-after-logo {
                &::after {
                    order: 0;
                }

                .navigation {
                    order: 1;

                    @media (min-width: 1000px) {
                        margin-bottom: var(--sidebar-padding);
                    }
                }

                &.logo-center {
                    .logo {
                        order: 1;
                        justify-content: center;
                    }
                }

                &.logo-opposite {
                    .logo {
                        order: -1;
                        justify-content: start;

                        img {
                            transform-origin: 0 50%;

                            @media (min-width: 1000px) {
                                transform-origin: 50% 0;
                            }
                        }
                    }
                }
            }

            &.menu-before-logo {
                &::after {
                    order: 0;
                }

                .navigation {
                    order: -1;

                    @media (min-width: 1000px) {
                        margin-top: var(--sidebar-padding);
                    }
                }

                &.logo-center {
                    .logo {
                        order: 0;
                        justify-content: center;
                    }
                }

                &.logo-opposite {
                    .logo {
                        order: 1;
                        justify-content: end;

                        img {
                            transform-origin: 100% 50%;

                            @media (min-width: 1000px) {
                                transform-origin: 50% 100%;
                            }
                        }
                    }
                }
            }

            .navigation {
                --translate-x: 0;
                --translate-y: -100%;

                z-index: -1;

                @media (min-width: 1000px) {
                    justify-self: center;
                }

                @media (max-width: 999px) {
                    display: grid;
                }

                &::after {
                    content: "";
                    position: absolute;
                    inset: 0;
                    background-color: var(--sidebar-background-color);
                    z-index: 0;
                }

                &::before {
                    content: "";
                    position: fixed;
                    inset: 0;
                    z-index: -1;
                    width: 100vw;
                    height: calc(100vh - var(--sidebar-size));
                    opacity: var(--navigation-alpha, 1);
                    background-color: var(--navigation-background-color);
                    transform: translateX(var(--translate-x, 0))
                        translateY(var(--translate-y, -100%));
                    transition: 160ms;
                    top: var(--sidebar-size);

                    @media (min-width: 1000px) {
                        top: 0;
                        height: 100vh;
                        width: calc(100vw - var(--sidebar-size));
                    }

                    @supports not (backdrop-filter: blur(1px)) {
                        opacity: clamp(
                            var(--navigation-alpha),
                            calc(var(--navigation-alpha) * 1.25),
                            90%
                        );
                    }
                }

                &.menu-active {
                    --translate-x: 0;
                    --translate-y: 0;

                    .center {
                        direction: ltr;
                        height: calc(100% - var(--sidebar-size));
                        transform: translateY(calc(var(--sidebar-size) - 1px));
                        padding-left: var(--content-padding);
                        padding-right: var(--content-padding);
                        background-color: hsla(0, 0%, 0%, 0.01%);

                        @media (max-width: 999px) {
                            width: 100%;
                            left: 0;
                            right: 0;
                        }

                        @media (min-width: 1000px) {
                            height: 100%;
                        }
                    }
                }
            }

            .info-text {
                position: static;
                padding: 0;
            }
        }
    }

    &.sidebar-left {
        .lightbox {
            top: var(--sidebar-size) !important;

            @media (min-width: 1000px) {
                top: 0 !important;
                left: var(--sidebar-size) !important;
            }

            img {
                // max-height: calc(80vh - var(--sidebar-size)) !important;
                // max-width: 70vw !important;
            }
        }

        .sidebar.burger {
            left: 0;

            @media (max-width: 999px) {
                right: 0;
                top: 0;
            }

            .navigation {
                @media (min-width: 1000px) {
                    --translate-y: 0;
                    --translate-x: -100%;

                    &::before {
                        left: var(--sidebar-size);
                        width: calc(100vw - var(--sidebar-size));
                        transform: translateX(var(--translate-x, -100%));
                    }
                }

                .center {
                    right: 100%;
                }

                .logo {
                    padding-right: 4rem;
                }

                @media (max-width: 999px) {
                    display: flex;
                    align-items: center;
                }

                &.menu-active {
                    .center {
                        height: 100%;
                        transform: translateY(var(--sidebar-size));

                        @media (max-width: 999px) {
                            right: auto;
                        }

                        @media (min-width: 1000px) {
                            left: auto;
                            transform: translateX(
                                calc(100% + var(--sidebar-size))
                            );
                        }
                    }
                }
            }
        }
    }

    &.sidebar-right {
        .lightbox {
            top: var(--sidebar-size) !important;

            @media (min-width: 1000px) {
                top: 0 !important;
                right: var(--sidebar-size) !important;
            }

            img {
                max-height: calc(80vh - var(--sidebar-size)) !important;
                max-width: 70vw !important;
            }
        }

        .sidebar.burger {
            right: 0;

            @media (max-width: 999px) {
                left: 0;
                top: 0;
            }

            .navigation {
                @media (min-width: 1000px) {
                    --translate-x: 100%;
                    --translate-y: 0;

                    &::before {
                        width: calc(100vw - var(--sidebar-size));
                        right: var(--sidebar-size);
                        left: 0;
                    }
                }

                .center {
                    left: 0;
                    right: 0;
                }

                .logo {
                    padding-left: 4rem;
                }

                @media (max-width: 999px) {
                    display: flex;
                    align-items: center;
                }

                &.menu-active {
                    &::before {
                        height: 100%;
                    }

                    .center {
                        @media (max-width: 999px) {
                            top: var(--sidebar-size);
                            transform: translateY(
                                calc(var(--translate-y) + var(--sidebar-size))
                            );
                        }

                        @media (min-width: 1000px) {
                            right: auto;
                            transform: translateX(
                                calc(var(--translate-x) - var(--sidebar-size))
                            );
                        }
                    }
                }
            }
        }
    }

    &.sidebar-top {
        .lightbox {
            top: var(--sidebar-size) !important;

            img {
                max-height: calc(80vh - var(--sidebar-size)) !important;
                max-width: 70vw !important;
            }
        }

        .alignfull {
            --page-width: 100vw;
        }

        .sidebar.burger {
            bottom: auto;
            right: 0;
            left: 0;
            top: 0;
            width: auto;
            grid-template-columns: repeat(3, 1fr);
            align-items: center;
            padding-left: var(--content-padding);
            padding-right: var(--content-padding);

            &::after {
                order: -1;
            }

            &.menu-before-logo {
                .navigation {
                    justify-self: start;
                }

                &.logo-opposite {
                    .logo {
                        justify-content: center;
                        align-items: flex-end;

                        img {
                            transform-origin: 100% 50%;
                        }
                    }
                }
            }

            &.menu-after-logo {
                .navigation {
                    justify-self: end;
                }

                &.logo-opposite {
                    .logo {
                        img {
                            transform-origin: 0% 50%;
                        }
                    }
                }
            }

            .navigation {
                --translate-x: 0;
                --translate-y: -100%;

                margin-top: auto !important;
                margin-bottom: auto !important;

                @media (min-width: 1000px) {
                    &::before {
                        width: 100vw;
                        height: calc(100vh - var(--sidebar-size));
                        top: var(--sidebar-size);
                    }
                }
            }

            .logo {
                position: static;
                top: auto;
                display: flex;
                flex-flow: column;
                justify-content: center !important;
                align-items: center;
            }

            @media (min-width: 1000px) {
                & {
                    height: var(--sidebar-size);
                    display: grid;
                    align-content: center;
                }

                .center {
                    width: 100%;
                    padding-left: calc((100% - var(--content-width)) / 2);
                    padding-right: calc((100% - var(--content-width)) / 2);
                }
            }
        }
    }
}

.sidebar-no-burger {
    .sidebar {
        justify-content: center;
        z-index: 5;
    }

    &.sidebar-left,
    &.sidebar-right {
        @media (min-width: 1000px) {
            .sidebar {
                max-width: calc(
                    (var(--sidebar-padding) * 2) + var(--sidebar-size)
                );
            }
        }
    }

    &.sidebar-left {
        .logo {
            transform-origin: left;
        }
    }

    &.sidebar-right {
        .logo {
            transform-origin: right;
        }
    }

    &.sidebar-top {
        .info-text {
            display: none;
        }

        .logo img {
            max-height: var(--sidebar-size);
        }
    }
}

.sidebar {
    grid-area: sidebar;
    display: grid;
    background-color: var(--sidebar-background-color);
    background-image: var(--sidebar-image);
    background-size: cover;
    align-content: space-between;
    z-index: 3;
    order: -1;

    &.box-shadow {
        box-shadow: var(--sidebar-shadow-offset-x)
                var(--sidebar-shadow-offset-y) var(--sidebar-shadow-blur)
                hsla(
                    var(--sidebar-shadow-color-hue),
                    calc(1% * var(--sidebar-shadow-color-saturation)),
                    calc(1% * var(--sidebar-shadow-color-lightness)),
                    var(--sidebar-shadow-color-opacity)
                ),
            calc(var(--sidebar-shadow-offset-x) / 2)
                calc(var(--sidebar-shadow-offset-y) / 2)
                calc(var(--sidebar-shadow-blur) / 2)
                hsla(
                    var(--sidebar-shadow-color-hue),
                    calc(1% * var(--sidebar-shadow-color-saturation)),
                    calc(1% * var(--sidebar-shadow-color-lightness)),
                    calc(var(--sidebar-shadow-color-opacity) / 2)
                ),
            calc(var(--sidebar-shadow-offset-x) / 3)
                calc(var(--sidebar-shadow-offset-y) / 3)
                calc(var(--sidebar-shadow-blur) / 3)
                hsla(
                    var(--sidebar-shadow-color-hue),
                    calc(1% * var(--sidebar-shadow-color-saturation)),
                    calc(1% * var(--sidebar-shadow-color-lightness)),
                    calc(var(--sidebar-shadow-color-opacity) / 3)
                ),
            calc(var(--sidebar-shadow-offset-x) / 4)
                calc(var(--sidebar-shadow-offset-y) / 4)
                calc(var(--sidebar-shadow-blur) / 4)
                hsla(
                    var(--sidebar-shadow-color-hue),
                    calc(1% * var(--sidebar-shadow-color-saturation)),
                    calc(1% * var(--sidebar-shadow-color-lightness)),
                    calc(var(--sidebar-shadow-color-opacity) / 4)
                );
    }

    .lightbox-visible & {
        z-index: 6 !important;
    }

    .logo-large,
    .info-text {
        display: none;
    }

    .logo {
        display: flex;
        transition: var(--transition-duration);

        img {
            flex: 0 0 auto;
            max-height: var(--sidebar-size);
            max-width: 100%;
        }

        @media (min-width: 1000px) {
            justify-items: center;
        }

        &:hover {
            transform: scale(1.025);
        }

        &:active {
            transform: scale(0.985);
        }
    }

    &.burger {
        position: fixed;
        z-index: 3;

        .logo-large {
            max-width: 100%;
            min-width: 1rem;
            padding: 0;
        }

        &::after {
            content: "";
            order: 10;
            height: 4rem;
        }

        @media (max-width: 999px) {
            grid-auto-flow: column;
            grid-template-columns: repeat(3, 1fr);
            padding-left: var(--content-padding);
            padding-right: var(--content-padding);
        }

        @media (min-width: 1000px) {
            width: var(--sidebar-size, 5rem);
            top: 0;
            bottom: 0;
        }
    }

    img {
        &.logo-large {
            transform: scale(var(--logo-large-scale));
        }

        &.logo-small {
            transform: scale(var(--logo-small-scale));
        }
    }

    @media (max-width: 999px) {
        align-content: center;
        min-height: var(--sidebar-size);
    }

    @media (min-width: 1000px) {
        align-content: space-between;

        &:not(.burger) {
            padding: var(--sidebar-padding);
        }

        .logo {
            position: sticky;
            top: var(--sidebar-padding);
            bottom: var(--sidebar-padding);
            z-index: 1;
        }

        img {
            width: 100%;
            max-height: none;

            &.logo-large {
                display: block;
            }

            &.logo-small {
                display: none;
            }
        }

        .info-text {
            display: block;
            position: sticky;
            bottom: var(--sidebar-padding);
            line-height: 1.6;
            color: var(--sidebar-text-color);
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;

            * + *:not(br) {
                margin-top: 0.5rem;
            }

            a {
                justify-items: stretch;
                color: var(--sidebar-link-color);
            }
        }
    }

    .navigation {
        font-family: var(--navigation-font-family),
            var(--navigation-font-fallback);

        .center {
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: calc(100% - var(--sidebar-size));
            transition: opacity var(--transition-duration, 200ms)
                calc(var(--transition-duration, 200ms) * 0.625);
            opacity: 0;
            pointer-events: none;
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
            will-change: opacity;
            display: flex;
            gap: 2rem;
            flex-flow: column;
            padding: var(--content-padding);

            @supports (backdrop-filter: blur(1px)) {
                backdrop-filter: blur(var(--navigation-backdrop-blur, 0));
            }

            @media (max-width: 999px) {
                transform: translateY(var(--sidebar-size));
            }

            @media (min-width: 1000px) {
                flex-flow: row wrap;
                padding-left: calc(
                    (100% - var(--content-width) - var(--sidebar-size)) / 2
                );
                padding-right: calc(
                    (100% - var(--content-width) - var(--sidebar-size)) / 2
                );
            }

            .menu {
                display: flex;
                flex-flow: column;
                justify-content: flex-start;
                flex: 1 1 auto;

                li {
                    list-style: none;
                    margin-top: 0.25rem;
                    margin-bottom: 0.25rem;

                    .toggle-children {
                        cursor: pointer;

                        & + .children {
                            opacity: 0;
                            pointer-events: none;
                            visibility: hidden;
                            max-height: 0;
                            margin: 0;
                            transition: var(--transition-duration);
                        }

                        &.active + .children {
                            opacity: 1;
                            pointer-events: all;
                            visibility: visible;
                            max-height: 100vh;
                        }
                    }
                }

                .children {
                    margin-top: 0.5rem;
                    margin-bottom: 1rem;

                    li {
                        margin-left: 1rem;

                        a {
                            font-size: calc(var(--navigation-font-size) * 0.8);
                        }
                    }
                }

                a {
                    display: inline-block;
                    padding-top: 0.25rem;
                    padding-bottom: 0.25rem;
                    font-size: var(--navigation-font-size);
                    color: var(--navigation-link-color);
                    font-weight: var(--navigation-font-weight);
                    text-transform: var(--navigation-text-transform, "none");
                    transition: var(--transition-duration);
                    transform-origin: left center;
                    transform: scale(1.00001);

                    &:hover {
                        transform: scale(1.015);
                    }

                    &:active {
                        transform: scale(0.985);
                    }

                    &.router-link-exact-active {
                        opacity: 0.5;
                        position: relative;
                        cursor: default;

                        &:hover,
                        &:active {
                            transform: none;
                        }

                        &::before {
                            content: "";
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            height: 2px;
                            background-color: var(--navigation-link-color);
                        }
                    }
                }
            }

            .info-text {
                flex: 0 1 auto;
                display: block;
                color: var(--navigation-text-color);
                padding: 1rem;

                > * + *:not(br) {
                    margin-top: 1rem;
                }

                ul,
                ol {
                    margin-left: 1rem;
                }
            }
        }

        &.menu-active {
            .center {
                transition-duration: var(--transition-duration);
                opacity: 1;
                pointer-events: all;
            }
        }

        &:not(.menu-active) {
            .center {
                @media (max-width: 999px) {
                    --translate-x: 0;
                    --translate-y: -100%;
                }

                transform: translateX(var(--translate-x, 0))
                    translateY(var(--translate-y, -100%));
                opacity: 0;
                pointer-events: none;
            }
        }
    }
}
</style>
