<template>
  <main
    v-if="pages.status === 'success'"
    id="one-page-container"
    class="one-page-container"
  >
    <section
      v-for="(page, index) in mainPages"
      :id="`${page.name}`"
      :data-page="page.name"
      :key="index"
      class="content page one-page-section"
    >
      <h1
        v-if="page.title"
        :key="`page-title-${page.id}`"
        v-html="page.title"
      />
      <content-blocks
        :key="`content-blocks-${page.id}`"
        :blocks="page.blocks"
      />
    </section>
  </main>
</template>

<script>
import { mapState } from 'vuex'
import ContentBlocks from '@/components/ContentBlocks.vue'

export default {
  name: 'OnePager',

  components: {
    ContentBlocks
  },

  computed: {
    ...mapState(['pages']),
    mainPages() {
      return this.pages.data.filter(page => page.parent === 0)
    }
  }
}
</script>
