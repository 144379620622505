<template>
    <div class="script-container" :class="withHtml">
        <div v-if="html" v-html="html" class="script-wrapper" />
        <div ref="script" class="script" />
    </div>
</template>

<script>
export default {
    name: 'Hue7Script',

    props: {
        url: {
            type: String,
            required: true
        },
        html: {
            type: String,
            required: false,
            default: null
        }
    },

    computed: {
        withHtml() {
            return this.html ? 'with-html' : 'no-html'
        }
    },

    mounted() {
        const scriptElement = document.createElement('script')
        scriptElement.setAttribute('src', this.url)
        this.$refs.script.appendChild(scriptElement)
    }
}
</script>

<style lang="scss">
.script-container {
    &.no-html .script {
        min-height: 10rem;
        background-color: hsla(0, 0%, 0%, 0.02);
        animation: pulsate 1s ease-out infinite alternate;
    }

    @keyframes pulsate {
        from {
            background-color: hsla(0, 0%, 0%, 0.02);
        }

        to {
            background-color: hsla(0, 0%, 0%, 0.18);
        }
    }

    .oax-embed-widget {
        height: 450px;
        overflow: hidden;
    }
}
</style>
