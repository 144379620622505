<template>
    <nav id="navigation" :class="{ 'menu-active': menuActive }" class="navigation">
        <div ref="menu" class="center">
            <ul v-if="jumpNavigation" class="jump-menu menu" @click.self="menuActive = !menuActive">
                <li
                    v-for="(jumpNavigationItem,
                    jumpNavigationIndex) in jumpNavigationItems"
                    :key="jumpNavigationIndex"
                >
                    <router-link
                        class="jump-navigation-item"
                        :to="`#${jumpNavigationItem.name}`"
                        @click.native="menuActive = !menuActive"
                        v-html="jumpNavigationItem.title"
                    />
                </li>
            </ul>
            <ul v-else class="menu" @click.self="menuActive = !menuActive">
                <li
                    v-for="(mainNavItem, mainNavItemIndex) in navigationItems"
                    :key="mainNavItemIndex"
                >
                    <router-link
                        v-if="!mainNavItem.children && mainNavItem.type !== 'custom'"
                        :to="mainNavItem.link"
                        @click.native="menuActive = !menuActive"
                        v-html="mainNavItem.title"
                    />
                    <a
                        v-else-if="!mainNavItem.children"
                        :href="mainNavItem.url"
                        :target="mainNavItem.target"
                        v-html="mainNavItem.title"
                    />
                    <a
                        v-if="mainNavItem.children"
                        :class="{
                            active:
                                mainMenuActive.includes(mainNavItem.ID) ||
                                mainNavItem.children.find(child => child.link === $route.path)
                        }"
                        class="toggle-children"
                        @click="toggleMenu(mainNavItem.ID)"
                        v-html="mainNavItem.title"
                    />
                    <ul v-if="mainNavItem.children" class="children">
                        <li
                            v-for="(subNavItem, subNavIndex) in mainNavItem.children"
                            :key="subNavIndex"
                        >
                            <router-link
                                v-if="subNavItem.type !== 'custom'"
                                :to="subNavItem.link"
                                @click.native="menuActive = false"
                                v-html="subNavItem.title"
                            />
                            <a
                                v-else
                                :href="subNavItem.url"
                                :target="subNavItem.target"
                                v-html="subNavItem.title"
                            />
                        </li>
                    </ul>
                </li>
            </ul>
            <div v-if="infoText" class="info-text" v-html="infoText" />
        </div>
        <v-burger
            v-if="burger"
            class="menu-toggle"
            :type="burgerType"
            :style="`--burger-zoom: ${burgerZoom};--burger-zoom-mobile: ${burgerZoomMobile};`"
            :active="menuActive"
            @updated="menuActive = $event"
        >
            <template v-if="burgerLabel" v-slot:label>
                <span class="hamburger-label">{{ burgerLabel }}</span>
            </template>
        </v-burger>
    </nav>
</template>

<script>
import { mapState } from 'vuex'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

export default {
    name: 'TheNavigation',

    props: {
        jumpNavigation: {
            type: Boolean,
            default: () => false
        },
        burger: {
            type: Boolean,
            default: () => false
        },
        infoText: {
            type: [String, Boolean],
            required: false,
            default: false
        }
    },

    data() {
        return {
            menuActive: false,
            mainMenuActive: [],
        }
    },

    computed: {
        ...mapState(['navigation', 'options']),
        burgerLabel() {
            return this.options.data['burger-label']
        },
        burgerType() {
            return this.options.data.sidebar['burger-type']
        },
        burgerZoom() {
            return this.options.data.sidebar['burger-zoom']
        },
        burgerZoomMobile() {
            return this.options.data.sidebar['burger-zoom-mobile']
        },
        jumpNavigationItems() {
            return this.navigation.data.filter(page => page.post_parent === 0)
        },
        navigationItems() {
            if (this.navigation.data.length) {
                const menuItems = this.navigation.data
                const mainLevel = menuItems.filter(item => {
                    return item.menu_item_parent === '0'
                })
                const subLevel = menuItems.filter(item => {
                    return item.menu_item_parent !== '0'
                })
                return mainLevel.map(item => {
                    subLevel.map(sub => {
                        if (sub.menu_item_parent == item.ID) {
                            if (!Array.isArray(item.children)) {
                                item.children = []
                            }
                            if (!item.children.find(({ ID }) => ID === sub.ID)) {
                                item.children.push(sub)
                            }
                        }
                        return sub
                    })
                    return item
                })
            } else {
                return false
            }
        }
    },

    watch: {
        menuActive(active) {
            if (this.burger) {
                if (!active) {
                    this.mainMenuActive = []
                }

                active
                    ? document.documentElement.classList.add('menu-visible')
                    : document.documentElement.classList.remove('menu-visible')

                active
                    ? disableBodyScroll(this.$refs.menu)
                    : enableBodyScroll(this.$refs.menu)
            }
        }
    },

    methods: {
        toggleMenu(menuID) {
            if (!this.mainMenuActive.includes(menuID)) {
                this.mainMenuActive.push(menuID)
            } else {
                const index = this.mainMenuActive.indexOf(menuID)
                this.mainMenuActive.splice(index, 1)
            }
        },
    }
}
</script>

<style lang="scss">
.menu-toggle {
    transform: scale(var(--burger-zoom, 1));
    display: inline-flex !important;
    align-items: center;
    gap: 0.5rem;
    z-index: 1;
    position: relative;

    @media (max-width: 999px) {
        transform: scale(var(--burger-zoom-mobile, var(--burger-zoom, 1)));
    }

    .hamburger-label {
        color: var(--sidebar-link-color);
    }

    &.hamburger,
    &.hamburger.is-active {
        .hamburger-inner {
            &,
            &::before,
            &::after {
                background-color: var(--sidebar-link-color);
            }
        }
    }
}

.app > .navigation {
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: var(--navigation-image);
    background-size: cover;
    background-color: var(--navigation-background-color, var(--black));
    font-family: var(--navigation-font-family), var(--navigation-font-fallback);
    font-weight: var(--navigation-font-weight);
    font-style: var(--navigation-font-style);
    font-size: var(--navigation-font-size);
    grid-area: navigation;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    align-self: baseline;

    @media (min-width: 700px) {
        position: sticky;
        z-index: 5;
        top: 0;
        left: auto;
        right: auto;
        bottom: auto;
        padding-left: 3rem;
        padding-right: 3rem;

        .menu-toggle {
            display: none;
        }
    }

    .center {
        width: 100%;
        max-width: var(--content-width);

        .menu {
            display: grid;
            grid-auto-flow: row;

            @media (max-width: 699px) {
                &::before {
                    content: var(--burger-label);
                    height: var(--navigation-height);
                    display: grid;
                    align-items: center;
                    justify-content: center;
                }
            }

            li {
                list-style: none;

                @media (max-width: 699px) {
                    display: grid;
                    justify-content: center;
                    align-items: center;
                }

                .children {
                    display: none;
                }
            }

            @media (min-width: 700px) {
                max-width: var(--content-width);
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                flex: 1 1 auto;
            }
        }
    }

    .jump-navigation-item {
        display: inline-block;
        cursor: pointer;
    }

    .jump-navigation-item,
    a {
        color: var(--navigation-link-color);
        text-decoration: none;
        text-transform: var(--navigation-text-transform, "none");
        white-space: nowrap;
        height: 3rem;
        padding: 0.5rem;
        transition: var(--transition-duration);
        text-align: left;
        align-content: center;
        display: inline-grid;

        &.router-link-exact-active,
        &:hover,
        &:focus {
            opacity: 0.35;
        }
    }

    @media (max-width: 699px) {
        flex-flow: column;
        transition: var(--transition-duration);
        z-index: 5;

        .center {
            order: 1;

            .jump-navigation-item,
            a {
                text-align: center;
                height: var(--navigation-height);
                font-size: 1.25rem;
            }
        }

        &:not(.menu-active) {
            transform: translateY(calc(100% - var(--navigation-height)));
        }
    }
}
</style>
