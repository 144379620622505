<template>
  <div
    v-if="media.status === 'success' && pages.status === 'success'"
    class="box"
  >
    <img
      v-if="image"
      :width="image.width"
      :height="image.height"
      :src="image.url"
      class="image"
    />
    <h2 v-html="title" />
    <div class="box-content" v-html="content" />
    <router-link
      v-if="box['link-type'] === 'internal'"
      :to="link"
      class="button"
      v-text="box['link-text']"
    />
    <a
      v-if="box['link-type'] === 'url'"
      :href="link"
      class="button"
      target="_blank"
      v-text="box['link-text']"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Hue7Box',

  props: {
    box: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapState(['media', 'pages']),
    title() {
      return this.box.title
    },
    image() {
      return {
        url: this.media.data.find(({ id }) => id == this.box.image).link,
        width: this.media.data.find(({ id }) => id == this.box.image).width,
        height: this.media.data.find(({ id }) => id == this.box.image).height
      }
    },
    content() {
      return this.box.content
    },
    link() {
      let link = false
      if (this.box['link-type'] === 'internal') {
        link = this.pages.data.find(
          ({ id }) => id === Number.parseInt(this.box['link-internal'])
        ).link
      }
      if (this.box['link-type'] === 'url') {
        link = this.box['link-url']
      }
      return link
    }
  }
}
</script>

<style lang="scss">
.boxes {
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: repeat(
    auto-fit,
    minmax(var(--box-min-width, 20rem), 1fr)
  );
}

.box {
  padding: 2rem;
  display: grid;
  grid-template-rows: max-content auto 1fr;
  align-content: start;
  background-color: var(--content-background-color);
  filter: drop-shadow(0 0.35rem 0.3rem hsla(0, 0%, 0%, 0.05));
  border-radius: var(--border-radius);
  position: relative;
  overflow: hidden;
  transform: translateZ(0px);

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    background-color: hsla(0, 0%, 100%, 0.12);
  }

  img {
    border-radius: var(--border-radius-top-left) var(--border-radius-top-right)
      0 0 !important;
    margin: -2rem -2rem 0;
    max-width: calc(100% + 4rem) !important;
    width: calc(100% + 4rem) !important;
    max-height: 70vh;
    object-fit: cover;
    object-position: 50% 50%;
  }

  .button {
    background-color: var(--page-accent-color);
    color: white;
    text-decoration: none;
    font-weight: 700;
    letter-spacing: 0.02rem;
    padding: 1rem;
    font-size: 1.15rem;
    justify-self: stretch;
    text-align: center;
    border-radius: calc(var(--border-radius-top-left) / 2)
      calc(var(--border-radius-top-right) / 2)
      calc(var(--border-radius-bottom-right) / 2)
      calc(var(--border-radius-bottom-left) / 2);
    transition: var(--transition-duration);
    cursor: pointer;

    &:hover,
    &:focus {
      opacity: 0.75;
    }
  }

  > * + * {
    margin-top: 2rem;
  }
}
</style>
