<template>
    <div
        v-if="successfullyLoaded([options, meta, pages, posts])"
        id="app"
        :class="[
            layout.onePage ? 'one-pager' : 'multi-pager',
            layout.sidebarToggle ? 'sidebar-visible' : 'sidebar-hidden',
            layout.sidebarBurger ? 'sidebar-burger' : 'sidebar-no-burger',
            layout.fullContainerWidth ? 'full-container-width' : '',
            `sidebar-${layout.sidebarPosition}`,
            resizing ? 'resizing' : ''
        ]"
        class="app"
    >
        <the-header :header-image="header.image" :logos="logos" />
        <the-navigation v-if="!layout.sidebarBurger" :jump-navigation="layout.onePage" />
        <router-view v-if="!layout.onePage" :key="$route.fullPath" />
        <one-pager v-else />
        <the-sidebar
            v-if="layout.sidebarToggle"
            :logo-large="logos.large"
            :logo-small="logos.small"
            :info-text="texts.info"
            :burger="layout.sidebarBurger"
            :menu-position="layout.sidebarBurgerMenuPosition"
            :logo-position="layout.sidebarBurgerLogoPosition"
            :class="{ 'box-shadow': layout.sidebarShadow.active }"
        />
        <the-footer v-if="texts.footer" :footer-columns="texts.footer" />
    </div>
</template>

<script>
import smoothReflow from 'vue-smooth-reflow'
import deepmerge from 'deepmerge'
import { mapState } from 'vuex'
import WebFont from 'webfontloader'
import TheHeader from '@/components/TheHeader.vue'
import TheNavigation from '@/components/TheNavigation.vue'
import OnePager from '@/components/OnePager.vue'
import TheSidebar from '@/components/TheSidebar.vue'
import TheFooter from '@/components/TheFooter.vue'

export default {
    name: 'Hue7',

    components: {
        TheHeader,
        TheNavigation,
        TheSidebar,
        TheFooter,
        OnePager
    },

    mixins: [smoothReflow],

    metaInfo() {
        const ogTitle = {
            name: 'og:title',
            content: this.metaData.title,
        }
        const ogDescription = {
            name: 'og:description',
            content: this.metaData.description,
        }
        const ogImage = {
            name: 'og:image',
            content: this.options.data['social-image'],
        }

        const ogMeta = [
            ogTitle,
            ogDescription,
            ogImage,
        ]

        return {
            titleTemplate:
                this.meta.status === 'success'
                    ? this.options.data['one-page-layout']
                        ? this.metaData.title
                        : `%s — ${this.metaData.title}`
                    : 'Lädt…',
            meta: [
                { charset: 'utf-8' },
                { name: 'description', content: this.metaData.description },
                ogTitle,
                ogDescription,
                ogImage,
            ]
        }
    },

    data() {
        return {
            resizing: false,
            previousHeight: 0
        }
    },

    computed: {
        ...mapState(['meta', 'options', 'pages', 'posts']),

        metaData() {
            const title = document.createElement('textarea')
            title.innerHTML = this.meta.data.name
            return {
                title: title.value,
                description: this.meta.data.description
            }
        },

        page() {
            return this.pages.status === 'success'
                ? this.pages.data.find(
                    page => page.link === this.$route.params.pathMatch
                ) || false
                : false
        },

        post() {
            return this.posts.status === 'success'
                ? this.posts.data.find(post => post.link === this.$route.path) ||
                false
                : false
        },

        layout() {
            return {
                onePage: this.options.data['one-page-layout'],
                fullContainerWidth: this.options.data['full-container-width'],
                containerWidth: this.options.data['full-container-width']
                    ? '100%'
                    : `${this.options.data['container-width']}rem`,
                contentWidth: `${this.options.data['content-width']}rem`,
                burgerLabel: ` ${this.options.data['burger-label']}`,
                burgerType: ` ${this.options.data['burger-type']}`,
                sidebarToggle: this.options.data['one-page-layout']
                    ? false
                    : this.options.data.sidebar.toggle,
                sidebarSize: `${this.options.data.sidebar.size}rem`,
                sidebarPosition: this.options.data.sidebar.position,
                sidebarBurger: this.options.data['one-page-layout']
                    ? false
                    : this.options.data.sidebar.burger,
                sidebarBurgerLogoPosition: `${this.options.data.sidebar['burger-logo-position']}`,
                sidebarBurgerMenuPosition: `${this.options.data.sidebar['burger-menu-position']}`,
                sidebarShadow: {
                    active: this.options.data.sidebar.shadow.active,
                    offset: {
                        x: `${this.options.data.sidebar.shadow.offset.x}rem`,
                        y: `${this.options.data.sidebar.shadow.offset.y}rem`
                    },
                    blur: `${this.options.data.sidebar.shadow.blur}rem`,
                    color: {
                        hue: this.options.data.sidebar.shadow.color.hue,
                        saturation: this.options.data.sidebar.shadow.color.saturation,
                        lightness: this.options.data.sidebar.shadow.color.lightness,
                        opacity: this.options.data.sidebar.shadow.color.opacity
                    }
                }
            }
        },

        header() {
            return {
                height: this.options.data.header.height,
                image: this.page.image || this.post.image || false
            }
        },

        logos() {
            return {
                large: {
                    image: this.options.data.logo.large.image,
                    scale: this.options.data.logo.large.scale
                },
                small: {
                    image: this.options.data.logo.small.image,
                    scale: this.options.data.logo.small.scale
                },
                margin: `${this.options.data.logo.margin}rem`,
                position: {
                    x: this.options.data.logo.position.x,
                    y: this.options.data.logo.position.y,
                    burger: this.options.data.logo.position.burger
                }
            }
        },

        colors() {
            return {
                page: {
                    background: this.options.data.colors.page.background,
                    accent: this.options.data.colors.page.accent
                },
                content: {
                    background: this.options.data.colors.content.background,
                    text: this.options.data.colors.content.text,
                    link: this.options.data.colors.content.link,
                    image: this.options.data.colors.content.image
                },
                heading: {
                    h1: this.options.data.colors.heading.h1,
                    h2: this.options.data.colors.heading.h2,
                    h3: this.options.data.colors.heading.h3
                },
                navigation: {
                    background: this.options.data.colors.navigation.background,
                    alpha: this.options.data.colors.navigation.alpha,
                    backdropBlur: this.options.data.colors.navigation['backdrop-blur'],
                    text: this.options.data.colors.navigation.text,
                    link: this.options.data.colors.navigation.link,
                    image: this.options.data.colors.navigation.image
                },
                sidebar: {
                    background: this.options.data.colors.sidebar.background,
                    text: this.options.data.colors.sidebar.text,
                    link: this.options.data.colors.sidebar.link,
                    image: this.options.data.colors.sidebar.image
                },
                footer: {
                    background: this.options.data.colors.footer.background,
                    text: this.options.data.colors.footer.text,
                    link: this.options.data.colors.footer.link,
                    image: this.options.data.colors.footer.image
                }
            }
        },

        fonts() {
            return {
                h1: {
                    family: this.options.data.fonts.h1.family,
                    weight: this.options.data.fonts.h1.weight,
                    fallback: this.options.data.fonts.h1.fallback,
                    style: this.options.data.fonts.h1.style,
                    size: this.options.data.fonts.h1.size,
                    transform: this.options.data.fonts.h1.transform
                },
                h2: {
                    family: this.options.data.fonts.h2.family,
                    weight: this.options.data.fonts.h2.weight,
                    fallback: this.options.data.fonts.h2.fallback,
                    style: this.options.data.fonts.h2.style,
                    size: this.options.data.fonts.h2.size,
                    transform: this.options.data.fonts.h2.transform
                },
                h3: {
                    family: this.options.data.fonts.h3.family,
                    weight: this.options.data.fonts.h3.weight,
                    fallback: this.options.data.fonts.h3.fallback,
                    style: this.options.data.fonts.h3.style,
                    size: this.options.data.fonts.h3.size,
                    transform: this.options.data.fonts.h3.transform
                },
                h4: {
                    family: this.options.data.fonts.h4.family,
                    weight: this.options.data.fonts.h4.weight,
                    fallback: this.options.data.fonts.h4.fallback,
                    style: this.options.data.fonts.h4.style,
                    size: this.options.data.fonts.h4.size,
                    transform: this.options.data.fonts.h4.transform
                },
                h5: {
                    family: this.options.data.fonts.h5.family,
                    weight: this.options.data.fonts.h5.weight,
                    fallback: this.options.data.fonts.h5.fallback,
                    style: this.options.data.fonts.h5.style,
                    size: this.options.data.fonts.h5.size,
                    transform: this.options.data.fonts.h5.transform
                },
                h6: {
                    family: this.options.data.fonts.h6.family,
                    weight: this.options.data.fonts.h6.weight,
                    fallback: this.options.data.fonts.h6.fallback,
                    style: this.options.data.fonts.h6.style,
                    size: this.options.data.fonts.h6.size,
                    transform: this.options.data.fonts.h6.transform
                },
                text: {
                    family: this.options.data.fonts.text.family,
                    weight: this.options.data.fonts.text.weight,
                    fallback: this.options.data.fonts.text.fallback,
                    style: this.options.data.fonts.text.style,
                    size: this.options.data.fonts.text.size,
                    lowerLimit: this.options.data.fonts.text.lowerLimit,
                    upperLimit: this.options.data.fonts.text.upperLimit,
                    transform: this.options.data.fonts.text.transform
                },
                navigation: {
                    family: this.options.data.fonts.navigation.family,
                    weight: this.options.data.fonts.navigation.weight,
                    fallback: this.options.data.fonts.navigation.fallback,
                    style: this.options.data.fonts.navigation.style,
                    size: this.options.data.fonts.navigation.size,
                    transform: this.options.data.fonts.navigation.transform
                }
            }
        },

        texts() {
            return {
                info: this.options.data.text.info,
                footer: this.options.data.text.footer
            }
        },

        borderRadius() {
            return {
                topLeft: this.options.data['border-radius']['top-left'],
                topRight: this.options.data['border-radius']['top-right'],
                bottomLeft: this.options.data['border-radius']['bottom-left'],
                bottomRight: this.options.data['border-radius']['bottom-right']
            }
        },

        modules() {
            return {
                news: {
                    dateVisibility: this.options.data['modules'].news['date-visibility'],
                    dateFormat: this.options.data['modules'].news['date-format'],
                    backgroundColor: this.options.data['modules'].news['background-color'],
                }
            }
        },

        scrollbarWidth() {
            return window.innerWidth - document.documentElement.clientWidth
        }
    },

    mounted() {
        this.$store.dispatch('GET_META')
        this.$store.dispatch('GET_OPTIONS')
        this.$store.dispatch('GET_NAVIGATION')
        this.$store.dispatch('GET_PAGES')
        this.$store.dispatch('GET_POSTS')
        this.$store.dispatch('GET_MEDIA')
        this.$store.dispatch('GET_FORMS')

        this.$smoothReflow({
            el: '#content',
            transitionEvent: {
                selector: 'img'
            }
        })

        window.addEventListener('resize', () => {
            this.resizing = true
            setTimeout(() => (this.resizing = false), 600)
        })

        this.$store.subscribe(mutation => {
            if (mutation.type === 'SET_OPTIONS_STATUS') {
                if (mutation.payload === 'success') {
                    this.setStyles()

                    if (this.layout.onePage && this.$route.path !== '/') {
                        this.$router.replace(`/${this.$route.hash}`)
                    }
                }
            }
        })
    },

    methods: {
        successfullyLoaded(items) {
            return items.every(({ status }) => status === 'success')
        },
        setStyles() {
            const fonts = [
                ...new Set([
                    `${this.fonts.h1.family}:${this.fonts.h1.weight}`,
                    `${this.fonts.h2.family}:${this.fonts.h2.weight}`,
                    `${this.fonts.h3.family}:${this.fonts.h3.weight}`,
                    `${this.fonts.h4.family}:${this.fonts.h4.weight}`,
                    `${this.fonts.navigation.family}:${this.fonts.navigation.weight}`,
                    `${this.fonts.text.family}:${this.fonts.text.weight}`,
                    `${this.fonts.text.family}:700`
                ])
            ]
                .map(font => {
                    const family = font.split(':')[0]
                    const weight = font.split(':')[1]
                    return {
                        [family]: {
                            weight: [[weight]]
                        }
                    }
                })
                .reduce((accumulator, currentValue) => {
                    return deepmerge(accumulator, currentValue)
                })

            for (const font in fonts) {
                fonts[font] = fonts[font].weight.join(',')
            }

            let fontStrings = Object.entries(fonts).map(font => {
                return `${font[0]}:${font[1]}:latin-ext`
            })

            WebFont.load({
                google: {
                    families: fontStrings
                }
            })

            const root = document.documentElement
            root.style.setProperty('--h1-font-family', this.fonts.h1.family)
            root.style.setProperty('--h1-font-weight', this.fonts.h1.weight)
            root.style.setProperty('--h1-font-style', this.fonts.h1.style)
            root.style.setProperty('--h1-font-size', `${this.fonts.h1.size}rem`)
            root.style.setProperty('--h1-text-transform', this.fonts.h1.transform)
            root.style.setProperty('--h1-font-fallback', this.fonts.h1.fallback)
            root.style.setProperty('--h1-color', this.colors.heading.h1)
            root.style.setProperty('--h2-font-family', this.fonts.h2.family)
            root.style.setProperty('--h2-font-weight', this.fonts.h2.weight)
            root.style.setProperty('--h2-font-style', this.fonts.h2.style)
            root.style.setProperty('--h2-font-size', `${this.fonts.h2.size}rem`)
            root.style.setProperty('--h2-text-transform', this.fonts.h2.transform)
            root.style.setProperty('--h2-font-fallback', this.fonts.h2.fallback)
            root.style.setProperty('--h2-color', this.colors.heading.h2)
            root.style.setProperty('--h3-font-family', this.fonts.h3.family)
            root.style.setProperty('--h3-font-weight', this.fonts.h3.weight)
            root.style.setProperty('--h3-font-style', this.fonts.h3.style)
            root.style.setProperty('--h3-font-size', `${this.fonts.h3.size}rem`)
            root.style.setProperty('--h3-text-transform', this.fonts.h3.transform)
            root.style.setProperty('--h3-font-fallback', this.fonts.h3.fallback)
            root.style.setProperty('--h3-color', this.colors.heading.h3)
            root.style.setProperty('--h4-font-family', this.fonts.h4.family)
            root.style.setProperty('--h4-font-weight', this.fonts.h4.weight)
            root.style.setProperty('--h4-font-style', this.fonts.h4.style)
            root.style.setProperty('--h4-font-size', `${this.fonts.h4.size}rem`)
            root.style.setProperty('--h4-text-transform', this.fonts.h4.transform)
            root.style.setProperty('--h4-font-fallback', this.fonts.h4.fallback)
            root.style.setProperty('--h4-color', this.colors.heading.h4)
            root.style.setProperty('--h5-color', this.colors.heading.h5)
            root.style.setProperty('--h5-font-family', this.fonts.h5.family)
            root.style.setProperty('--h5-font-weight', this.fonts.h5.weight)
            root.style.setProperty('--h5-font-style', this.fonts.h5.style)
            root.style.setProperty('--h5-font-size', `${this.fonts.h5.size}rem`)
            root.style.setProperty('--h5-text-transform', this.fonts.h5.transform)
            root.style.setProperty('--h5-font-fallback', this.fonts.h5.fallback)
            root.style.setProperty('--h6-color', this.colors.heading.h6)
            root.style.setProperty('--h6-font-family', this.fonts.h6.family)
            root.style.setProperty('--h6-font-weight', this.fonts.h6.weight)
            root.style.setProperty('--h6-font-style', this.fonts.h6.style)
            root.style.setProperty('--h6-font-size', `${this.fonts.h6.size}rem`)
            root.style.setProperty('--h6-text-transform', this.fonts.h6.transform)
            root.style.setProperty('--h6-font-fallback', this.fonts.h6.fallback)
            root.style.setProperty('--text-font-family', this.fonts.text.family)
            root.style.setProperty('--text-font-weight', this.fonts.text.weight)
            root.style.setProperty('--text-font-style', this.fonts.text.style)
            root.style.setProperty('--text-font-size-upper-limit', `${this.fonts.text.upperLimit}px`)
            root.style.setProperty('--text-font-size-lower-limit', `${this.fonts.text.lowerLimit}px`)
            root.style.setProperty('--text-text-transform', this.fonts.text.transform)
            root.style.setProperty('--text-font-fallback', this.fonts.text.fallback)
            root.style.setProperty(
                '--navigation-font-family',
                this.fonts.navigation.family
            )
            root.style.setProperty(
                '--navigation-font-weight',
                this.fonts.navigation.weight
            )
            root.style.setProperty(
                '--navigation-font-style',
                this.fonts.navigation.style
            )
            root.style.setProperty(
                '--navigation-font-size',
                `${this.fonts.navigation.size}rem`
            )
            root.style.setProperty(
                '--navigation-text-transform',
                this.fonts.navigation.transform
            )
            root.style.setProperty(
                '--navigation-font-fallback',
                this.fonts.navigation.fallback
            )
            root.style.setProperty(
                '--page-background-color',
                this.colors.page.background
            )
            root.style.setProperty(
                '--border-radius-top-left',
                `${this.borderRadius.topLeft}`
            )
            root.style.setProperty(
                '--border-radius-top-right',
                `${this.borderRadius.topRight}`
            )
            root.style.setProperty(
                '--border-radius-bottom-left',
                `${this.borderRadius.bottomLeft}`
            )
            root.style.setProperty(
                '--border-radius-bottom-right',
                `${this.borderRadius.bottomRight}`
            )
            root.style.setProperty('--page-accent-color', this.colors.page.accent)
            root.style.setProperty('--container-width', this.layout.containerWidth)
            root.style.setProperty('--content-width', this.layout.contentWidth)
            root.style.setProperty('--burger-label', `'${this.layout.burgerLabel}'`)
            root.style.setProperty(
                '--content-background-color',
                this.colors.content.background
            )
            root.style.setProperty('--content-text-color', this.colors.content.text)
            root.style.setProperty('--content-link-color', this.colors.content.link)
            root.style.setProperty(
                '--footer-background-color',
                this.colors.footer.background
            )
            root.style.setProperty('--footer-text-color', this.colors.footer.text)
            root.style.setProperty('--footer-link-color', this.colors.footer.link)
            root.style.setProperty(
                '--sidebar-background-color',
                this.colors.sidebar.background
            )
            root.style.setProperty('--sidebar-text-color', this.colors.sidebar.text)
            root.style.setProperty('--sidebar-link-color', this.colors.sidebar.link)
            root.style.setProperty('--sidebar-size', this.layout.sidebarSize)
            root.style.setProperty('--sidebar-position', this.layout.sidebarPosition)
            root.style.setProperty('--sidebar-burger', this.layout.sidebarBurger)
            root.style.setProperty(
                '--sidebar-shadow-offset-x',
                `${this.layout.sidebarShadow.offset.x}`
            )
            root.style.setProperty(
                '--sidebar-shadow-offset-y',
                `${this.layout.sidebarShadow.offset.y}`
            )
            root.style.setProperty(
                '--sidebar-shadow-blur',
                `${this.layout.sidebarShadow.blur}`
            )
            root.style.setProperty(
                '--sidebar-shadow-color-hue',
                `${this.layout.sidebarShadow.color.hue}`
            )
            root.style.setProperty(
                '--sidebar-shadow-color-saturation',
                `${this.layout.sidebarShadow.color.saturation}`
            )
            root.style.setProperty(
                '--sidebar-shadow-color-lightness',
                `${this.layout.sidebarShadow.color.lightness}`
            )
            root.style.setProperty(
                '--sidebar-shadow-color-opacity',
                `${this.layout.sidebarShadow.color.opacity}`
            )
            root.style.setProperty(
                '--navigation-background-color',
                this.colors.navigation.background
            )
            root.style.setProperty(
                '--navigation-text-color',
                this.colors.navigation.text
            )
            root.style.setProperty(
                '--navigation-link-color',
                this.colors.navigation.link
            )
            root.style.setProperty(
                '--navigation-backdrop-blur',
                `${this.colors.navigation.backdropBlur}px`
            )
            root.style.setProperty(
                '--navigation-alpha',
                `${this.colors.navigation.alpha}%`
            )
            root.style.setProperty('--header-height', `${this.header.height}vh`)
            root.style.setProperty('--logo-small-scale', this.logos.small.scale)
            root.style.setProperty('--logo-large-scale', this.logos.large.scale)
            root.style.setProperty('--logo-position-y', this.logos.position.y)
            root.style.setProperty('--logo-position-x', this.logos.position.x)
            root.style.setProperty('--scrollbar-width', `${this.scrollbarWidth}px`)

            if (this.colors.navigation.image) {
                root.style.setProperty(
                    '--navigation-image',
                    `url(${this.colors.navigation.image})`
                )
            }

            if (this.colors.content.image) {
                root.style.setProperty(
                    '--content-image',
                    `url(${this.colors.content.image})`
                )
            }

            if (this.colors.footer.image) {
                root.style.setProperty(
                    '--footer-image',
                    `url(${this.colors.footer.image})`
                )
            }

            if (this.colors.sidebar.image) {
                root.style.setProperty(
                    '--sidebar-image',
                    `url(${this.colors.sidebar.image})`
                )
            }
        }
    }
}
</script>

<style lang="scss">
.grow-enter-active,
.grow-leave-active {
    transition: calc(2 * var(--transition-duration, 200ms));
    transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
    will-change: max-height;
    filter: brightness(1) saturate(1);
}

.grow-leave-to,
.grow-enter {
    max-height: 0 !important;
    min-height: 0 !important;
    filter: brightness(0) saturate(0);
}

.grow-leave,
.grow-enter-to {
    max-height: var(--header-height);
    min-height: var(--header-height);
}

.blur-fade-enter-active,
.blur-fade-leave-active {
    transition: calc(var(--transition-duration) * 1);
    transition-timing-function: ease-out;
    transition-delay: var(--transition-duration);
    will-change: auto;
}

.shrink-fade-leave-to,
.shrink-fade-enter {
    opacity: 0;
    transform: scale(0.5);
}

.shrink-fade-leave,
.shrink-fade-enter-to {
    opacity: 1;
    transform: scale(1);
}

*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    box-sizing: border-box;
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transform-origin: 50% 50%;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
}

*:focus {
    outline: 0;
}

::selection {
    background-color: var(--page-accent-color, black);
    color: white;
}

:root,
button,
input,
textarea {
    font-family: var(--text-font-family, "sans-serif");
    font-weight: var(--text-font-weight, 400);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--h1-font-family), var(--h1-font-fallback);
    font-weight: var(--h1-font-weight, 700);
    font-style: var(--h1-font-style, "normal");
    text-transform: var(--h1-text-transform, "none");
    font-size: 1rem;
    letter-spacing: -0.05rem;
    line-height: 1.2;
}

h1 {
    color: var(--h1-color);
    line-height: 1;
    font-size: var(--h1-font-size);
}

h2 {
    font-family: var(--h2-font-family), var(--h2-font-fallback);
    font-weight: var(--h2-font-weight, 700);
    font-style: var(--h2-font-style, "normal");
    text-transform: var(--h2-text-transform, "none");
    color: var(--h2-color);
    font-size: var(--h2-font-size);
}

h3 {
    font-family: var(--h3-font-family), var(--h3-font-fallback);
    font-weight: var(--h3-font-weight, 700);
    font-style: var(--h3-font-style, "normal");
    text-transform: var(--h3-text-transform, "none");
    color: var(--h3-color);
    font-size: var(--h3-font-size);
}

h4 {
    font-family: var(--h4-font-family), var(--h4-font-fallback);
    font-weight: var(--h4-font-weight, 700);
    font-style: var(--h4-font-style, "normal");
    text-transform: var(--h4-text-transform, "none");
    color: var(--h4-color);
    font-size: var(--h4-font-size);
}

h5 {
    font-family: var(--h5-font-family), var(--h5-font-fallback);
    font-weight: var(--h5-font-weight, 700);
    font-style: var(--h5-font-style, "normal");
    text-transform: var(--h5-text-transform, "none");
    color: var(--h5-color);
    font-size: var(--h5-font-size);
}

h6 {
    font-family: var(--h6-font-family), var(--h6-font-fallback);
    font-weight: var(--h6-font-weight, 700);
    font-style: var(--h6-font-style, "normal");
    text-transform: var(--h6-text-transform, "none");
    color: var(--h6-color);
    font-size: var(--h6-font-size);
}

b,
strong {
    font-weight: 700;
}

i,
em {
    font-style: italic;
}

a {
    text-decoration: none;
    color: var(--page-accent-color);
}

:root {
    --transition-duration: 300ms;
    --sidebar-size: 20rem;
    --sidebar-padding: 2rem;
    --content-padding: 2rem;
    --navigation-height: 4rem;
    --border-radius: var(--border-radius-top-left)
        var(--border-radius-top-right) var(--border-radius-bottom-left)
        var(--border-radius-bottom-right);

    --page-width: 100vw;
    @media (min-width: 1000px) {
        --page-width: calc(100vw - var(--sidebar-size));
    }

    @media (min-width: 600px) {
        --content-padding: 3rem;
    }
    @media (min-width: 800px) {
        --content-padding: 5rem;
    }
    @media (min-width: 1000px) {
        --content-padding: 4rem;
    }

    font-size: clamp(
        var(--text-font-size-lower-limit),
        calc(2vw),
        var(--text-font-size-upper-limit)
    );

    @media (min-width: 1000px) {
        &[style*="--sidebar-position: left"][style*="--sidebar-burger: true"],
        &[style*="--sidebar-position:left"][style*="--sidebar-burger:true"] {
            body {
                transition: var(--transition-duration);
                border-left: var(--sidebar-size) solid
                    var(--sidebar-background-color);
            }

            &.menu-visible,
            &.lightbox-visible {
                body {
                    border-left-width: calc(
                        var(--sidebar-size) + var(--scrollbar-width)
                    );
                }
            }
        }

        &[style*="--sidebar-position: right"][style*="--sidebar-burger: true"],
        &[style*="--sidebar-position:right"][style*="--sidebar-burger:true"] {
            body {
                transition: var(--transition-duration);
                border-right: var(--sidebar-size) solid
                    var(--sidebar-background-color);
            }

            &.menu-visible,
            &.lightbox-visible {
                body {
                    border-right-width: calc(
                        var(--sidebar-size) + var(--scrollbar-width)
                    );
                }
            }
        }
    }
}

:root,
body {
    min-height: 100%;
    max-width: 100%;
    background-color: var(--page-background-color);
    transition: background 200ms;
}

body {
    display: grid;
    align-items: center;
    overflow-y: scroll;
}

.app {
    display: flex;
    flex-flow: column;
    background-color: var(--content-background-color);
    justify-content: flex-start;

    &.sidebar-burger {
        @media (max-width: 999px) {
            padding-top: var(--sidebar-size);
        }

        &.sidebar-left,
        &.sidebar-right {
            max-width: var(--container-width);
        }
    }

    &.sidebar-top.sidebar-burger {
        padding-top: var(--sidebar-size);
    }

    @media (min-width: 600px) {
        display: grid;
        grid-template-rows: min-content auto auto max-content 1fr;
        grid-template-columns: 1fr;
        grid-template-areas:
            "sidebar"
            "header"
            "navigation"
            "content"
            "footer";

        &.sidebar-hidden {
            grid-template-rows: min-content 1fr min-content min-content min-content;
            grid-template-areas:
                "header"
                "navigation"
                "content"
                "footer";
        }

        &.sidebar-burger {
            grid-template-rows: min-content 1fr min-content min-content min-content;
            grid-template-areas:
                "header"
                "content"
                "footer";
        }

        &.one-pager {
            grid-template-rows: min-content 1fr min-content min-content;
            grid-template-areas:
                "header"
                "navigation"
                "content"
                "footer";
        }
    }

    @media (min-width: 1000px) {
        &.sidebar-right {
            grid-template-columns: 1fr auto;
            grid-template-rows: min-content min-content 1fr min-content;
            grid-template-areas:
                "header sidebar"
                "navigation sidebar"
                "content sidebar"
                "footer sidebar";
        }

        &.sidebar-left {
            grid-template-columns: auto 1fr;
            grid-template-rows: min-content min-content 1fr min-content;
            grid-template-areas:
                "sidebar header"
                "sidebar navigation"
                "sidebar content"
                "sidebar footer";
        }

        &.sidebar-hidden {
            grid-template-columns: 1fr;
            grid-template-rows: min-content min-content 1fr min-content;
            grid-template-areas:
                "header"
                "navigation"
                "content"
                "footer";
        }

        &.sidebar-burger {
            grid-template-columns: 1fr;
            grid-template-rows: min-content min-content 1fr min-content;
            grid-template-areas:
                "header"
                "content"
                "footer";
        }

        &.one-pager {
            grid-template-rows: min-content min-content min-content min-content;
            grid-template-areas:
                "header"
                "navigation"
                "content"
                "footer";
        }
    }

    max-width: var(--container-width, 100%);
    min-height: 100vh;
    width: 100%;
    margin: 0 auto;
    transition: calc(var(--transition-duration) * 3);
    color: var(--content-text-color, black);
    justify-content: center;
    transform-origin: top center;

    &.resizing {
        &,
        * {
            transition-duration: 0s !important;
            transition-delay: 0s !important;
        }
    }
}

.content {
    position: relative;
    line-height: 1.6;
    background-color: var(--content-background-color);
    background-image: var(--content-image);
    background-size: cover;
    color: var(--content-text-color);
    text-transform: var(--text-text-transform, none);
    padding: var(--content-padding);
    z-index: 4;
    flex-grow: 1;
    overflow: hidden;
    min-height: 100vh;

    &.fade-enter-active,
    &.fade-leave-active {
        transition-property: height, opacity;
        transition-duration: calc(var(--transition-duration) * 2);
        overflow: hidden;
    }

    &.fade-enter,
    &.fade-leave-to {
        opacity: 0;
    }

    > h1,
    .blocks {
        max-width: var(--content-width);
        margin: auto;
    }

    h1 {
        margin-bottom: 2rem;
    }

    ul,
    ol {
        margin-left: 2rem;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    a {
        hyphens: auto;
    }

    .has-small-font-size {
        font-size: 0.8rem;
    }

    .has-normal-font-size {
        font-size: 1rem;
    }

    .has-medium-font-size {
        font-size: 1.35rem;
    }

    .has-large-font-size {
        font-size: 2.5rem;
    }

    .has-huge-font-size {
        font-size: 4rem;
    }

    .has-text-align-right {
        text-align: right;
    }

    .has-text-align-left {
        text-align: left;
    }

    .has-text-align-center {
        text-align: center;
    }

    .has-undefined-background-color {
        background-color: var(--background-color);
    }

    .has-pale-pink-background-color {
        background-color: #f78da7;
    }

    .has-vivid-red-background-color {
        background-color: #cf2e2e;
    }

    .has-luminous-vivid-orange-background-color {
        background-color: #ff6900;
    }

    .has-luminous-vivid-amber-background-color {
        background-color: #fcb900;
    }

    .has-light-green-cyan-background-color {
        background-color: #7bdcb5;
    }

    .has-vivid-green-cyan-background-color {
        background-color: #00d084;
    }

    .has-pale-cyan-blue-background-color {
        background-color: #8ed1fc;
    }

    .has-vivid-cyan-blue-background-color {
        background-color: #0693e3;
    }

    .has-vivid-purple-background-color {
        background-color: #9b51e0;
    }

    .has-very-light-gray-background-color {
        background-color: #eee;
    }

    .has-cyan-bluish-gray-background-color {
        background-color: #abb8c3;
    }

    .has-very-dark-gray-background-color {
        background-color: #313131;
    }

    .has-pale-pink-color {
        color: #f78da7;
    }

    .has-vivid-red-color {
        color: #cf2e2e;
    }

    .has-luminous-vivid-orange-color {
        color: #ff6900;
    }

    .has-luminous-vivid-amber-color {
        color: #fcb900;
    }

    .has-light-green-cyan-color {
        color: #7bdcb5;
    }

    .has-vivid-green-cyan-color {
        color: #00d084;
    }

    .has-pale-cyan-blue-color {
        color: #8ed1fc;
    }

    .has-vivid-cyan-blue-color {
        color: #0693e3;
    }

    .has-vivid-purple-color {
        color: #9b51e0;
    }

    .has-very-light-gray-color {
        color: #eee;
    }

    .has-cyan-bluish-gray-color {
        color: #abb8c3;
    }

    .has-very-dark-gray-color {
        color: #313131;
    }

    .has-undefined-color {
        color: var(--text-color);
    }

    .has-vivid-cyan-blue-to-vivid-purple-gradient-background {
        background: linear-gradient(
            135deg,
            rgba(6, 147, 227, 1) 0%,
            rgb(155, 81, 224) 100%
        );
    }

    .has-vivid-green-cyan-to-vivid-cyan-blue-gradient-background {
        background: linear-gradient(
            135deg,
            rgba(0, 208, 132, 1) 0%,
            rgba(6, 147, 227, 1) 100%
        );
    }

    .has-light-green-cyan-to-vivid-green-cyan-gradient-background {
        background: linear-gradient(
            135deg,
            rgb(122, 220, 180) 0%,
            rgb(0, 208, 130) 100%
        );
    }

    .has-luminous-vivid-amber-to-luminous-vivid-orange-gradient-background {
        background: linear-gradient(
            135deg,
            rgba(252, 185, 0, 1) 0%,
            rgba(255, 105, 0, 1) 100%
        );
    }

    .has-luminous-vivid-orange-to-vivid-red-gradient-background {
        background: linear-gradient(
            135deg,
            rgba(255, 105, 0, 1) 0%,
            rgb(207, 46, 46) 100%
        );
    }

    .has-very-light-gray-to-cyan-bluish-gray-gradient-background {
        background: linear-gradient(
            135deg,
            rgb(238, 238, 238) 0%,
            rgb(169, 184, 195) 100%
        );
    }

    .has-cool-to-warm-spectrum-gradient-background {
        background: linear-gradient(
            135deg,
            rgb(74, 234, 220) 0%,
            rgb(151, 120, 209) 20%,
            rgb(207, 42, 186) 40%,
            rgb(238, 44, 130) 60%,
            rgb(251, 105, 98) 80%,
            rgb(254, 248, 76) 100%
        );
    }

    .has-blush-light-purple-gradient-background {
        background: linear-gradient(
            135deg,
            rgb(255, 206, 236) 0%,
            rgb(152, 150, 240) 100%
        );
    }

    .has-blush-bordeaux-gradient-background {
        background: linear-gradient(
            135deg,
            rgb(254, 205, 165) 0%,
            rgb(254, 45, 45) 50%,
            rgb(107, 0, 62) 100%
        );
    }

    .has-purple-crush-gradient-background {
        background: linear-gradient(
            135deg,
            rgb(52, 226, 228) 0%,
            rgb(71, 33, 251) 50%,
            rgb(171, 29, 254) 100%
        );
    }

    .has-luminous-dusk-gradient-background {
        background: linear-gradient(
            135deg,
            rgb(255, 203, 112) 0%,
            rgb(199, 81, 192) 50%,
            rgb(65, 88, 208) 100%
        );
    }

    .has-hazy-dawn-gradient-background {
        background: linear-gradient(
            135deg,
            rgb(250, 172, 168) 0%,
            rgb(218, 208, 236) 100%
        );
    }

    .has-pale-ocean-gradient-background {
        background: linear-gradient(
            135deg,
            rgb(255, 245, 203) 0%,
            rgb(182, 227, 212) 50%,
            rgb(51, 167, 181) 100%
        );
    }

    .has-electric-grass-gradient-background {
        background: linear-gradient(
            135deg,
            rgb(202, 248, 128) 0%,
            rgb(113, 206, 126) 100%
        );
    }

    .has-subdued-olive-gradient-background {
        background: linear-gradient(
            135deg,
            rgb(250, 250, 225) 0%,
            rgb(103, 166, 113) 100%
        );
    }

    .has-atomic-cream-gradient-background {
        background: linear-gradient(
            135deg,
            rgb(253, 215, 154) 0%,
            rgb(0, 74, 89) 100%
        );
    }

    .has-nightshade-gradient-background {
        background: linear-gradient(
            135deg,
            rgb(51, 9, 104) 0%,
            rgb(49, 205, 207) 100%
        );
    }

    .has-midnight-gradient-background {
        background: linear-gradient(
            135deg,
            rgb(2, 3, 129) 0%,
            rgb(40, 116, 252) 100%
        );
    }

    a {
        color: var(--content-link-color);
        transition: var(--transition-duration);
        text-decoration: underline;
        text-decoration-color: var(--content-link-color);

        &:hover,
        &:focus {
            text-decoration-color: hsla(0, 0%, 100%, 0);
        }
    }

    img,
    video,
    iframe {
        max-width: 100%;
    }

    > .blocks {
        padding-bottom: 4rem;
    }

    .blocks {
        display: flex;
        flex-flow: column;

        @media (max-width: 500px) {
            max-width: calc(var(--page-width) - (2 * var(--content-padding)));

            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                hyphens: auto;
            }
        }

        > div {
            &.core-heading + * {
                margin-top: 1.5rem;
            }

            &:not(.core-heading) + * {
                margin-top: 2.75rem;
            }

            &.core-paragraph + .core-paragraph {
                margin-top: 1rem;
            }
        }

        img {
            max-width: 100%;
            height: auto;
            object-fit: cover;
            border-radius: var(--border-radius);
        }

        .alignfull {
            margin-left: calc((var(--page-width) * -1) / 2 + 100% / 2);
            margin-right: calc((var(--page-width) * -1) / 2 + 100% / 2);
            max-width: 100vw;
            justify-items: stretch !important;

            &.wp-block-image {
                border-radius: 0;
            }

            img {
                display: block;
                width: 100%;
                border-radius: 0;
            }

            figcaption {
                padding: 0 1rem;
            }
        }

        .alignleft {
            justify-self: start;
        }

        .alignright {
            justify-self: end;
        }

        .aligncenter {
            margin: auto;
            display: block;
        }

        .vimeo,
        .youtube {
            position: relative;

            &.wp-embed-aspect-16-9 {
                padding-top: calc(100% / (16 / 9));
            }

            iframe {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
            }
        }

        .wp-block-image {
            display: grid;
            border-radius: var(--border-radius);
            overflow: hidden;
            align-items: center;
            justify-items: center;

            &.size-large {
                img {
                    width: 100%;
                }
            }
        }
    }
}
</style>
