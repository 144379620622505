<template>
    <transition-group
        v-if="pages.status === 'success' && page.title"
        id="content"
        tag="main"
        class="content page"
        name="shrink-fade"
    >
        <h1 v-if="page.title" :key="`page-title-${page.id}`" v-html="page.title" />
        <content-blocks
            v-if="page.blocks"
            :key="`content-blocks-${page.id}`"
            :blocks="page.blocks"
        />
    </transition-group>
</template>

<script>
import { mapState } from 'vuex'
import ContentBlocks from '@/components/ContentBlocks.vue'

export default {
    name: 'Page',

    components: {
        ContentBlocks
    },

    computed: {
        ...mapState(['pages', 'meta']),
        page() {
            return (
                this.pages.data.find(
                    page => page.link === this.$route.params.pathMatch
                ) || { title: false, id: 0, blocks: false }
            )
        },
        name() {
            return this.meta.data.name
        }
    },

    metaInfo() {
        return {
            title:
                this.pages.status !== 'success'
                    ? 'Lädt…'
                    : this.page
                        ? this.page.title
                        : ''
        }
    }
}
</script>
