import Vue from 'vue'
import App from './App.vue'
import VueScrollTo from 'vue-scrollto'
import VueAnalytics from 'vue-analytics'
import VBurger from 'vue-burger'
import VueMeta from 'vue-meta'
import VueMoment from 'vue-moment'
import Vue2TouchEvents from 'vue2-touch-events'
import VueBlurHash from 'vue-blurhash'
import VueLazyload from 'vue-lazyload'
import router from './router'
import store from './store'
import axios from 'axios'
import moment from 'moment'

Vue.config.productionTip = false

moment.locale('de-ch')
Vue.use(VueMoment, {
  moment,
})
Vue.use(VueScrollTo, {
  duration: 800,
})
Vue.use(VueMeta)
Vue.use(Vue2TouchEvents)
Vue.use(VueAnalytics, {
  router,
  id: axios
    .get('/api/hue7/options')
    .then(({ data }) => data['google-analytics']),
  set: [
    {
      field: 'anonymizeIp',
      value: true,
    },
  ],
})
Vue.use(VBurger)
Vue.use(VueBlurHash)
Vue.use(VueLazyload, {
  preLoad: 0,
  observer: true,
  observerOptions: {
    rootMargin: '0px',
    threshold: 0.1,
  },
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
