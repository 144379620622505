<template>
    <div class="blocks">
        <template v-for="(block, blockIndex) in blocks">
            <template v-if="block.blockName === 'acf/box'">
                <div
                    :key="`${block.blockName.replace('/', '-')}-${blockIndex}`"
                    :style="`--box-min-width:${block.attrs.data['box-min-width']}rem`"
                    class="boxes"
                >
                    <hue7-box
                        v-for="(box, index) in block.attrs.data.box"
                        :key="`box-${index}`"
                        :box="getBoxData(block.attrs.data, index)"
                    />
                </div>
            </template>
            <template v-else-if="block.blockName === 'core/group'">
                <div
                    :key="`${block.blockName.replace('/', '-')}-${blockIndex}`"
                    :class="
                        `${block.attrs.align}-width has-${block.attrs.backgroundColor}-background-color has-${block.attrs.textColor}-color`
                    "
                    :style="
                        `--background-color:${block.attrs.customBackgroundColor};--text-color:${block.attrs.customTextColor};`
                    "
                    class="group"
                >
                    <content-blocks
                        :key="`group-${block.blockName.replace('/', '-')}-${blockIndex}`"
                        :blocks="block.innerBlocks"
                    />
                </div>
            </template>
            <template v-else-if="block.blockName === 'acf/script'">
                <hue7-script
                    :key="`${block.blockName.replace('/', '-')}-${blockIndex}`"
                    :url="block.attrs.data['script-url']"
                    :html="block.attrs.data['script-html']"
                />
            </template>
            <template v-else-if="block.blockName === 'core/columns'">
                <div
                    :key="`${block.blockName.replace('/', '-')}-${blockIndex}`"
                    :class="
                        `has-${block.attrs.backgroundColor}-background-color has-${block.attrs.textColor}-color`
                    "
                    class="columns"
                >
                    <hue7-column
                        v-for="(innerBlock, index) in block.innerBlocks"
                        :key="`${block.blockName.replace('/', '-')}-${blockIndex}-${index}`"
                        :column="innerBlock"
                    />
                </div>
            </template>
            <template v-else-if="block.blockName === 'core-embed/youtube'">
                <div
                    :key="`${block.blockName.replace('/', '-')}-${blockIndex}`"
                    :class="block.attrs.className"
                    class="youtube"
                    allowfullscreen
                >
                    <iframe
                        :src="
                            `https://youtube.com/embed/${block.attrs.url.split('?v=')[1]
                            }?modestbranding=1&rel=0&showinfo=0`
                        "
                        frameborder="0"
                    />
                </div>
            </template>
            <template v-else-if="block.blockName === 'core-embed/vimeo'">
                <div
                    :key="`${block.blockName.replace('/', '-')}-${blockIndex}`"
                    :class="block.attrs.className"
                    class="vimeo"
                    allowfullscreen
                >
                    <iframe
                        :src="
                            `https://player.vimeo.com/video/${block.attrs.url.split('.com/')[1]
                            }?byline=0&portrait=0&badge=0&title=0&color=ffffff`
                        "
                        frameborder="0"
                    />
                </div>
            </template>
            <template v-else-if="block.blockName === 'acf/form'">
                <hue7-form
                    :id="Number.parseInt(block.attrs.data['form-id'])"
                    :key="`${block.blockName.replace('/', '-')}-${blockIndex}`"
                />
            </template>
            <template v-else-if="block.blockName === 'acf/news'">
                <hue7-news :key="`${block.blockName.replace('/', '-')}-${blockIndex}`" />
            </template>
            <template v-else-if="block.blockName === 'acf/table'">
                <hue7-table
                    :key="`${block.blockName.replace('/', '-')}-${blockIndex}`"
                    :table="block.attrs.data.table"
                    :align="block.attrs.align_text"
                />
            </template>
            <template v-else-if="block.blockName === 'core/gallery'">
                <template
                    v-if="
                        block.attrs.className &&
                        block.attrs.className.includes('is-style-lightbox')
                    "
                >
                    <hue7-gallery
                        :key="`${block.blockName.replace('/', '-')}-${blockIndex}`"
                        :ids="block.attrs.ids"
                        :columns="block.attrs.columns"
                        :image-crop="block.attrs.imageCrop"
                    />
                </template>
                <template
                    v-if="
                        block.attrs.className &&
                        block.attrs.className.includes('is-style-slider')
                    "
                >
                    <hue7-slider
                        :key="`${block.blockName.replace('/', '-')}-${blockIndex}`"
                        :ids="block.attrs.ids"
                        :columns="block.attrs.columns"
                    />
                </template>
            </template>
            <template v-else>
                <div
                    :key="`${block.blockName.replace('/', '-')}-${blockIndex}`"
                    :class="block.blockName.replace('/', '-')"
                    v-html="block.innerHTML"
                />
            </template>
        </template>
    </div>
</template>

<script>
import Hue7Box from '@/components/blocks/Hue7Box.vue'
import Hue7Column from '@/components/blocks/Hue7Column.vue'
import Hue7Form from '@/components/blocks/Hue7Form.vue'
import Hue7Gallery from '@/components/blocks/Hue7Gallery.vue'
import Hue7Slider from '@/components/blocks/Hue7Slider.vue'
import Hue7News from '@/components/blocks/Hue7News.vue'
import Hue7Script from '@/components/blocks/Hue7Script.vue'
import Hue7Table from '@/components/blocks/Hue7Table.vue'

export default {
    name: 'ContentBlocks',

    components: {
        Hue7Box,
        Hue7Column,
        Hue7Form,
        Hue7Gallery,
        Hue7Slider,
        Hue7News,
        Hue7Script,
        Hue7Table
    },

    props: {
        blocks: {
            type: Array,
            required: true
        }
    },

    methods: {
        getBoxData(data, index) {
            const box = Object.entries(data)
                .filter(box => {
                    return box[0].startsWith(`box_${index}_`)
                })
                .map(box => {
                    return { [box[0].replace(`box_${index}_`, '')]: box[1] }
                })
                .reduce((accumulator, element) => {
                    return { ...accumulator, ...element }
                })
            return box
        }
    }
}
</script>

<style lang="scss">
.group {
    padding: 1rem;
    max-width: var(--page-width);

    &.wide-width {
        margin-left: calc((var(--page-width) * -1) / 2 + 100% / 2);
        margin-right: calc((var(--page-width) * -1) / 2 + 100% / 2);
    }

    &.full-width {
        margin-left: calc((var(--page-width) * -1) / 2 + 100% / 2);
        margin-right: calc((var(--page-width) * -1) / 2 + 100% / 2);

        > .blocks {
            max-width: 100%;
        }
    }
}
</style>
