<template>
  <div
    ref="lightbox"
    :class="{ visible: activeImage }"
    class="lightbox"
    @click.self="setActiveImageID(false)"
  >
    <button class="next" @click="nextImage">
      <svg viewBox="0 0 512 512">
        <polygon
          points="160,128.4 192.3,96 352,256 352,256 352,256 192.3,416 160,383.6 287.3,256 "
        />
      </svg>
    </button>
    <button class="previous" @click="previousImage">
      <svg viewBox="0 0 512 512">
        <polygon
          points="352,128.4 319.7,96 160,256 160,256 160,256 319.7,416 352,383.6 224.7,256 "
        />
      </svg>
    </button>
    <figure :style="`---aspect-ratio: ${activeImage.aspectRatio}`">
      <img
        ref="lightbox-image"
        v-lazy="{
          src: activeImage.imageSize.M,
          loading: activeImage.preview
        }"
        v-if="activeImage"
        v-touch:swipe.right="previousImage"
        v-touch:swipe.left="nextImage"
        :key="activeImage.src"
        :title="activeImage.caption"
        :data-src="activeImage.src"
        :data-srcset="activeImage.srcset"
        :width="activeImage.width"
        :height="activeImage.height"
        :class="transitionDirection"
        loading="lazy"
      />
    </figure>
    <div v-if="activeImage.caption" class="caption">
      {{ activeImage.caption }}
    </div>
    <button class="close" @click="setActiveImageID(false)">
      <svg viewBox="0 0 48 48">
        <path
          d="M38 12.83l-2.83-2.83-11.17 11.17-11.17-11.17-2.83 2.83 11.17 11.17-11.17 11.17 2.83 2.83 11.17-11.17 11.17 11.17 2.83-2.83-11.17-11.17z"
        />
      </svg>
    </button>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'Lightbox',

  props: {
    images: {
      type: Array,
      required: true
    },
    ids: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      transitionDirection: 'to-left'
    }
  },

  computed: {
    ...mapState(['lightbox']),

    activeImageID() {
      return this.lightbox.activeImageID
    },

    activeImage() {
      return this.images.find(({ id }) => id === this.activeImageID)
    },

    currentIndex() {
      return this.ids.indexOf(this.activeImageID)
    }
  },

  mounted() {
    document.addEventListener('keydown', event =>
      this.keyboardNavigation(event)
    )
  },

  destroyed() {
    document.documentElement.classList.remove('lightbox-visible')
    document.removeEventListener('keydown', this.keyboardNavigation)
  },

  methods: {
    ...mapMutations({
      setActiveImageID: 'SET_LIGHTBOX_ACTIVE_IMAGE'
    }),

    previousImage() {
      if (this.currentIndex <= 0) {
        this.setActiveImageID(this.ids[this.ids.length - 1])
      } else {
        this.setActiveImageID(this.ids[this.currentIndex - 1])
      }

      this.transitionDirection = ''
      setTimeout(() => {
        this.transitionDirection = 'to-right'
      }, 50)
    },

    nextImage(event) {
      if (this.currentIndex >= this.ids.length - 1) {
        this.setActiveImageID(this.ids[0])
      } else {
        this.setActiveImageID(this.ids[this.currentIndex + 1])
      }

      this.transitionDirection = ''
      setTimeout(() => {
        this.transitionDirection = 'to-left'
      }, 50)
    },

    keyboardNavigation(event) {
      // keyCodes: 39 → / 37 ← / 27 ⎋
      const { keyCode } = event

      if (keyCode === 39) {
        this.nextImage()
      } else if (keyCode === 37) {
        this.previousImage()
      } else if (keyCode === 27) {
        this.setActiveImageID(false)
      }
    }
  }
}
</script>
<style lang="scss">
.lightbox {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  z-index: 1;
  background-color: hsla(0, 0%, 0%, 0.82);
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  transition: var(--transition-duration);

  @supports (backdrop-filter: blur(30px)) {
    backdrop-filter: blur(20px) saturate(1.5);
  }

  .close {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: none;
    border: 0;
    cursor: pointer;
    width: 100%;
    height: 2.5vmax;
    padding: 0.5rem;
    box-sizing: content-box;

    svg {
      width: 100%;
      height: 100%;

      path {
        transition: var(--transition-duration);
        fill: var(--page-accent-color, white);
      }
    }

    &:hover {
      svg {
        path {
          fill: white;
        }
      }

      &:active {
        svg {
          path {
            transform: scale(0.85);
          }
        }
      }
    }
  }

  &.visible {
    opacity: 1;
    pointer-events: all;
  }

  figure {
    --offset: 82;

    display: grid;
    place-items: center;
    overflow: hidden;
    position: relative;
    width: calc(var(--offset) * 1vw);
    height: calc(var(--offset) * 1vh);
    pointer-events: none;
  }

  img {
    --image-transform-y: -1rem;
    --image-scale: 0.96;
    --image-slide-distance: 3vw;
    --image-blur: 20px;

    object-fit: contain !important;
    max-height: 100%;
    max-width: 100%;
    overflow: hidden;
    border-radius: var(--border-radius);
    opacity: 0;
    transform: scale(var(--image-scale)) transformY(var(--image-transform-y));
    transition: all var(--transition-duration) cubic-bezier(0.22, 0.61, 0.36, 1);
    filter: blur(var(--image-blur));

    &[lazy='loading'] {
      --image-scale: 1.1;
      --image-blur: 30px;
    }

    &[lazy='loaded'] {
      --image-blur: 0px;
      --image-scale: 1;
    }

    @keyframes to-left {
      from {
        transform: translateX(var(--image-slide-distance))
          scale(var(--image-scale));
        opacity: 0;
      }

      to {
        transform: translateX(0);
        opacity: 1;
      }
    }

    @keyframes to-right {
      from {
        transform: translateX(calc(-1 * var(--image-slide-distance)))
          scale(var(--image-scale));
        opacity: 0;
      }

      to {
        transform: translateX(0);
        opacity: 1;
      }
    }

    &.to-left {
      animation: to-left calc(3 * var(--transition-duration))
        cubic-bezier(0.22, 0.61, 0.36, 1) forwards 1;
    }

    &.to-right {
      animation: to-right calc(3 * var(--transition-duration))
        cubic-bezier(0.22, 0.61, 0.36, 1) forwards 1;
    }
  }

  .previous,
  .next {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    width: 2.5vmax;
    background: none;
    border: 0;
    cursor: pointer;

    polygon {
      fill: var(--page-accent-color, white);
      transition: var(--transition-duration, 200ms);
    }

    &:hover {
      polygon {
        fill: white;
      }
    }

    &:active {
      polygon {
        transform: scale(0.8);
      }
    }
  }

  .previous {
    left: 0;

    polygon {
      transform-origin: right center;
    }
  }

  .next {
    right: 0;

    polygon {
      transform-origin: left center;
    }
  }

  .caption {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    color: white;
    padding: 3rem 5vw 2vh;
    overflow: hidden;
    text-overflow: ellipsis;
    z-index: 1;
    text-shadow: 0 0.15rem 0.3rem hsla(0, 0%, 0%, 0.8);
    background-image: linear-gradient(
      to bottom,
      hsla(0, 0%, 0%, 0),
      hsla(0, 0%, 0%, 0.8)
    );
    pointer-events: none;
  }
}
</style>
