<template>
    <transition-group
        v-if="successfullyLoaded([meta, posts])"
        id="content"
        tag="div"
        class="content post"
        name="shrink-fade"
    >
        <em
            v-if="dateVisibility"
            :key="`post-date-${post.id}`"
            class="date"
        >{{ post.date | moment('dddd, D. MMMM Y') }}</em>
        <h1 v-if="post.title" :key="`post-title-${post.id}`" v-html="post.title" />
        <content-blocks :key="`content-blocks-${post.id}`" :blocks="post.blocks" />
        <a
            :key="`back-link-${post.id}`"
            class="back-link"
            @click="$router.go(-1)"
            v-text="'Zurück'"
        />
    </transition-group>
</template>

<script>
import { mapState } from 'vuex'
import ContentBlocks from '@/components/ContentBlocks.vue'

export default {
    name: 'News',

    components: {
        ContentBlocks
    },

    computed: {
        ...mapState(['posts', 'meta', 'options']),
        post() {
            return this.posts.data.find(post => post.link === this.$route.path)
        },
        dateVisibility() {
            return this.options.data['modules'].news['date-visibility']
        }
    },

    metaInfo() {
        return {
            title: this.posts.status !== 'success' ? 'Lädt…' : this.post.title
        }
    },

    methods: {
        successfullyLoaded(items) {
            return items.every(({ status }) => status === 'success')
        }
    }
}
</script>

<style>
.content .date {
    display: block;
    margin-bottom: 2rem;
}

.date,
.back-link {
    max-width: var(--content-width);
    margin: auto;
    display: block;
}

.back-link {
    cursor: pointer;
}
</style>
