<template>
    <table :class="`align-${align}`">
        <caption v-if="caption" v-html="caption" />
        <thead v-if="showHeaders">
            <tr>
                <th v-for="(header, headerIndex) in headers" :key="`header-${headerIndex}`">
                    <span v-html="header" />
                </th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(row, rowIndex) in rows" :key="`row-${rowIndex}`">
                <td
                    v-for="(column, columnIndex) in row"
                    :key="`column-${columnIndex}`"
                    :style="`--label:'${headers[columnIndex]}:'`"
                    v-html="column.c"
                />
            </tr>
        </tbody>
        <tfoot></tfoot>
    </table>
</template>

<script>
export default {
    name: 'Hue7Table',

    props: {
        table: {
            type: Object,
            required: true
        },
        align: {
            type: String,
            required: false,
            default: 'none'
        }
    },

    computed: {
        parts() {
            const table = this.table
            delete table.acftf
            return table
        },
        caption() {
            return this.parts.p.ca
        },
        headers() {
            return this.parts.h.map(header => header.c)
        },
        rows() {
            return this.parts.b
        },
        showHeaders() {
            return !!this.parts.p.o.uh
        }
    }
}
</script>

<style lang="scss">
table {
    border-collapse: collapse;

    @media (max-width: 999px) {
        &,
        * {
            display: block;
        }

        tr {
            margin-top: 3rem;
            border-bottom: 1px solid;

            td {
                border-bottom: 1px solid;
                display: flex;
                align-items: center;
            }
        }
    }

    @media (max-width: 999px) {
        thead {
            display: none;
        }
    }

    &.align-left {
        text-align: left;
    }

    &.align-center {
        &,
        th {
            text-align: center;
        }
    }

    &.align-right {
        &,
        th {
            text-align: right;
        }
    }
}

caption {
    text-align: left;
    margin-bottom: 2rem;
}

tr {
    th,
    td {
        min-height: 2rem;
        min-width: 5rem;

        @media (min-width: 1000px) {
            padding: 0.25rem;
        }

        @media (min-width: 1000px) {
            &:not(:last-of-type) {
                border-right: 1px solid;
            }
        }
    }

    th {
        text-align: left;

        @media (min-width: 1000px) {
            border-bottom: 1px solid;
        }
    }

    td {
        @media (max-width: 999px) {
            &::before {
                content: var(--label);
                font-weight: 700;
                display: inline-block;
                margin-right: 0.5rem;
            }
        }
    }

    @media (min-width: 1000px) {
        &:not(:last-of-type) {
            td {
                border-bottom: 1px solid;
            }
        }
    }
}
</style>
