<template>
    <form
        v-if="forms.status === 'success'"
        :ref="`form-${form.id}`"
        :name="`form-${form.name}`"
        :class="{ 'validation-hints': validationHintsVisibility }"
        @keyup="checkFormValidity(`form-${form.id}`)"
        @click.self="validationHints"
        @submit.prevent
    >
        <div v-if="formSent" :class="{ 'fade-out': fadeOutThankYouNote }" class="thank-you">
            <b>Vielen Dank.</b>
            <span>Ihre Nachricht wurde erfolgreich übermittelt.</span>
        </div>
        <template v-for="(field, index) in form.fields">
            <label
                v-if="field.type === 'text' || field.type === 'email'"
                :key="index"
                :class="`${field.width}-width`"
            >
                <input
                    :type="field.type"
                    :name="field.name"
                    :required="!field.optional"
                    pattern=".{2,}"
                />
                <span>{{ field.label }}</span>
            </label>
            <div
                v-if="field.type === 'checkbox' || field.type === 'radio'"
                :key="index"
                :class="`${field.width}-width`"
            >
                <label v-for="(choice, choiceIndex) in field.choices" :key="choiceIndex">
                    <input
                        :type="field.type"
                        :name="`${field.name}[]`"
                        :value="choice['choice']"
                        :required="!field.optional"
                    />
                    <span>{{ choice['choice'] }}</span>
                </label>
                <span>{{ field.label }}</span>
            </div>
            <div v-if="field.type === 'select'" :key="index" :class="`${field.width}-width`">
                <select :name="field.name" :required="!field.optional">
                    <option
                        v-for="(choice, choiceIndex) in field.choices"
                        :key="choiceIndex"
                        :value="choice['choice']"
                        v-text="choice['choice']"
                    />
                </select>
                <span>{{ field.label }}</span>
            </div>
            <label v-if="field.type === 'textarea'" :key="index" :class="`${field.width}-width`">
                <textarea :name="field.name" :required="!field.optional" rows="5" />
                <span>{{ field.label }}</span>
            </label>
        </template>
        <button
            :disabled="!formValidity"
            type="button"
            name="submit"
            @click="submitForm('form-' + form.id)"
            v-text="'Senden'"
        />
    </form>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'

export default {
    name: 'Hue7Form',

    props: {
        id: {
            type: Number,
            required: true
        }
    },

    data() {
        return {
            formSent: false,
            formValidity: false,
            validationHintsVisibility: false,
            fadeOutThankYouNote: false
        }
    },

    computed: {
        ...mapState([
            'forms'
        ]),
        form() {
            return this.forms.data.find(form => form.id === this.id)
        }
    },

    methods: {
        checkFormValidity(formID) {
            this.formValidity = this.$refs[formID].checkValidity()
        },
        validationHints() {
            this.validationHintsVisibility = true
            setTimeout(() => {
                this.validationHintsVisibility = false
            }, 5800)
        },

        formData(formID) {
            let formData = { id: this.form.id }
            this.form.fields.map(field => {
                let fieldValue
                if (field.type === 'radio') {
                    fieldValue = this.$refs[formID].elements[`${field.name}[]`].value
                } else if (field.type === 'checkbox') {
                    let checkedBoxes = document.querySelectorAll(`[name="${field.name}[]"]:checked`)
                    let choices = []
                    checkedBoxes.forEach(choice => choices.push(choice.value))
                    fieldValue = choices.join(', ')
                } else {
                    fieldValue = this.$refs[formID].elements[field.name].value
                }

                formData = Object.assign(formData, { [field.name]: fieldValue })
            })
            return formData
        },

        submitForm(formID) {
            if (this.$refs[formID].checkValidity()) {
                axios.post('/api/hue7/form', this.formData(formID))
                    .then(({ data }) => {
                        this.formSent = data
                        this.$refs[formID].reset()
                        this.$scrollTo(this.$refs[formID], 400, {
                            offset: 0
                        })
                        setTimeout(() => {
                            this.fadeOutThankYouNote = true
                            setTimeout(() => {
                                this.formSent = false
                                this.formValidity = false
                                this.fadeOutThankYouNote = false
                            }, 1000)
                        }, 5000)
                    })
                    .catch(error => console.error(error))
            }
        }
    }
}
</script>

<style lang="scss">
form {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    .thank-you {
        display: block;
    }

    &.validation-hints {
        :invalid {
            outline: 1px solid crimson;
        }
    }

    > div,
    > label {
        padding-top: 1rem;
        width: 100%;
        display: flex;
        flex-flow: column;

        @media (min-width: 600px) {
            &.half-width {
                width: calc(50% - 0.5rem);
            }
        }

        > span {
            display: block;
            margin-bottom: 0.5rem;
            order: -1;
        }

        > [required] + span {
            &::after {
                content: " *";
            }
        }
    }

    input,
    textarea,
    select {
        height: 3rem;
        width: 100%;
        border: 1px solid var(--content-text-color);
        font-size: 1rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        border-radius: var(--border-radius);

        &[type="radio"],
        &[type="checkbox"] {
            height: auto;
            width: auto;
            margin-right: 0.5rem;
        }
    }

    button {
        border-radius: var(--border-radius);
        padding: 1rem;
        border: 1px solid var(--content-text-color);
        background: white;
        margin-top: 2rem;
        font-size: 1rem;
    }
}
</style>
