var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.successfullyLoaded([_vm.options, _vm.meta, _vm.pages, _vm.posts]))?_c('div',{staticClass:"app",class:[
        _vm.layout.onePage ? 'one-pager' : 'multi-pager',
        _vm.layout.sidebarToggle ? 'sidebar-visible' : 'sidebar-hidden',
        _vm.layout.sidebarBurger ? 'sidebar-burger' : 'sidebar-no-burger',
        _vm.layout.fullContainerWidth ? 'full-container-width' : '',
        ("sidebar-" + (_vm.layout.sidebarPosition)),
        _vm.resizing ? 'resizing' : ''
    ],attrs:{"id":"app"}},[_c('the-header',{attrs:{"header-image":_vm.header.image,"logos":_vm.logos}}),(!_vm.layout.sidebarBurger)?_c('the-navigation',{attrs:{"jump-navigation":_vm.layout.onePage}}):_vm._e(),(!_vm.layout.onePage)?_c('router-view',{key:_vm.$route.fullPath}):_c('one-pager'),(_vm.layout.sidebarToggle)?_c('the-sidebar',{class:{ 'box-shadow': _vm.layout.sidebarShadow.active },attrs:{"logo-large":_vm.logos.large,"logo-small":_vm.logos.small,"info-text":_vm.texts.info,"burger":_vm.layout.sidebarBurger,"menu-position":_vm.layout.sidebarBurgerMenuPosition,"logo-position":_vm.layout.sidebarBurgerLogoPosition}}):_vm._e(),(_vm.texts.footer)?_c('the-footer',{attrs:{"footer-columns":_vm.texts.footer}}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }