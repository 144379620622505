import Vue from 'vue'
import Router from 'vue-router'
import VueScrollTo from 'vue-scrollto'
import Page from './views/Page.vue'
import News from './views/News.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/news/:post',
      name: 'news',
      component: News,
    },
    {
      path: '*',
      name: 'page',
      component: Page,
    },
  ],
  scrollBehavior(to) {
    if (to.hash.length) {
      VueScrollTo.scrollTo(to.hash)
    } else {
      const app = document.querySelector('#app')
      if (app) {
        VueScrollTo.scrollTo(app)
      }
    }
  },
})
