<template>
    <ul
        v-if="media.status === 'success' && posts.status === 'success'"
        class="news"
        :style="`--background-color:${backgroundColor};--text-color:${textColor}`"
    >
        <li v-for="(post, index) in posts.data" :key="index" class="post">
            <img v-if="post.image" :src="image(post.image).src" :srcset="image(post.image).srcset" />
            <em v-if="dateVisibility" class="date">{{ post.date | moment(dateFormatString) }}</em>
            <h3 class="title">{{ post.title }}</h3>
            <p class="excerpt" v-html="post.excerpt" />
            <router-link :to="post.link" class="link" v-text="'Weiterlesen'" />
        </li>
    </ul>
</template>

<script>
import { mapState } from "vuex"

export default {
    name: "Hue7News",

    computed: {
        ...mapState(["media", "posts", "options"]),
        dateVisibility() {
            return this.options.data["modules"].news["date-visibility"]
        },
        backgroundColor() {
            return this.options.data["modules"].news["background-color"]
        },
        textColor() {
            return this.options.data["modules"].news["text-color"]
        },
        dateFormat() {
            return this.options.data["modules"].news["date-format"]
        },
        dateFormatString() {
            let dateFormat = "dddd, D. MMMM Y"

            if (this.dateFormat === 'short') {
                dateFormat = "DD.MM.YY"
            }

            if (this.dateFormat === 'medium') {
                dateFormat = "D. MMMM YYYY"
            }

            if (this.dateFormat === 'full') {
                dateFormat = "dddd, D. MMMM YYYY"
            }

            return dateFormat
        }
    },

    methods: {
        image(imageID) {
            return this.media.data.find(({ id }) => id === imageID)
        }
    }
}
</script>

<style lang="scss">
.content .news {
    margin: 0;
    padding: 0;
    list-style: none;
    display: grid;
    grid-template-rows: auto 1fr;
    grid-gap: 1rem;
    margin-bottom: 2rem;
    grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));

    .post {
        --post-grid-gap: 1rem;
        background-color: var(--background-color, white);
        color: var(--text-color, black);
        border-radius: var(--border-radius);
        overflow: hidden;
        display: grid;
        grid-auto-flow: row;
        grid-gap: var(--post-grid-gap);
        grid-template-columns: 0 auto 0;
        grid-template-rows: auto auto auto auto 1fr;
        padding-bottom: var(--post-grid-gap);

        > * {
            grid-column: 2 / -2;
            margin: 0;

            &:not(a) {
                color: inherit;
            }
        }

        em:first-child,
        h3:first-child {
            margin-top: 1rem;
        }

        img {
            grid-column: 1 / -1;
            width: 100%;
            height: 12rem;
            object-fit: cover;
            object-position: 50% 80%;
        }

        .link {
            align-self: end;
        }

        .date {
            font-size: 0.85rem;
        }

        .excerpt {
            font-size: 0.9rem;
        }
    }
}
</style>
