<template>
    <div v-if="media.status === 'success'" ref="slider" class="slider">
        <slider :options="options" :slides-to-show="columns">
            <img
                v-for="(image, index) in filteredMedia"
                :id="`image-${image.id}`"
                :key="index"
                :height="image.height"
                :width="image.width"
                :src="image.src"
                :srcset="image.srcset"
                class="image"
                sizes="100vw"
                :tabindex="index"
            />
            <template slot="prevButton">
                <svg viewBox="0 0 512 512">
                    <polygon
                        points="352,128.4 319.7,96 160,256 160,256 160,256 319.7,416 352,383.6 224.7,256 "
                    />
                </svg>
            </template>
            <template slot="nextButton">
                <svg viewBox="0 0 512 512">
                    <polygon
                        points="160,128.4 192.3,96 352,256 352,256 352,256 192.3,416 160,383.6 287.3,256 "
                    />
                </svg>
            </template>
        </slider>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { VueAgile } from 'vue-agile'

export default {
    name: 'Hue7Slider',

    components: {
        slider: VueAgile
    },

    props: {
        ids: {
            type: Array,
            required: true
        },
        columns: {
            type: [Number, Boolean],
            required: false,
            default: 1
        }
    },

    data() {
        return {
            options: {
                asNavFor: [],
                autoplay: false,
                autoplaySpeed: 3000,
                centerMode: false,
                dots: false,
                fade: false,
                infinite: true,
                initialSlide: 0,
                mobileFirst: true,
                navButtons: true,
                pauseOnDotsHover: true,
                pauseOnHover: true,
                speed: 1100,
                timing: 'ease-in-out',
                slidesToScroll: 1,
                slidesToShow: 1,
                responsive: [
                    {
                        breakpoint: 400,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2
                        }
                    },
                    {
                        breakpoint: 800,
                        settings: {
                            slidesToShow: this.columns,
                            slidesToScroll: this.columns
                        }
                    }
                ]
            },
        }
    },

    computed: {
        ...mapState([
            'media'
        ]),
        sanitizedIds() {
            return this.ids.map(id => parseInt(id, 10))
        },
        filteredMedia() {
            return this.media.data.filter(({ id }) => this.sanitizedIds.includes(id)).sort((a, b) => {
                return this.sanitizedIds.indexOf(a.id) > this.sanitizedIds.indexOf(b.id) ? 1 : -1
            })
        }
    }
}
</script>

<style lang="scss">
.content .blocks .slider {
    img {
        border-radius: var(--border-radius);
        transition: var(--transition-duration) !important;
        transition-timing-function: ease-in-out;
        object-fit: contain;
        cursor: pointer;
        max-width: none;
        max-height: 75vh;
        width: auto;
    }

    .agile {
        max-width: calc(100vw - (2 * var(--content-padding)));
    }

    .agile__actions {
        margin-top: 1rem;

        .agile__nav-button {
            border: 0;
            font-size: 1rem;
            padding: 0;
            cursor: pointer;
            display: grid;
            justify-content: center;
            align-items: center;
            box-shadow: 0 0.5rem 0.7rem hsla(0, 0%, 0%, 0.03);

            &:hover {
                polygon {
                    filter: brightness(0.5);
                }
            }

            &:active {
                polygon {
                    transform: scale(0.8);
                }
            }
        }

        svg {
            width: 2rem;
            height: 2rem;
        }

        polygon {
            fill: var(--page-accent-color, white);
            transition: var(--transition-duration, 200ms);
        }

        .agile__nav-button--prev {
            polygon {
                transform-origin: right center;
            }
        }

        .agile__nav-button--next {
            polygon {
                transform-origin: left center;
            }
        }
    }
}
</style>
